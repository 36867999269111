import React from "react";
import {Link} from "react-router-dom";
import SiteFooter from "../SiteFooter";
import {Page} from "tabler-react";


export const StandaloneFormPage = (props) => {
    return <Page >
        <Page.Main>
            <div>
                <div>
                    <Page.Content>
                    <div className="container">
                        <div className="row">
                            <div className={`col ${props.formClass || 'col-login'} mx-auto`}>
                                <div className="text-center mb-6">
                                    <Link to={"/"}>
                                        <img src={props.imageURL} className="form-logo" alt={props.imageAlt || "Logo"}/>
                                    </Link>
                                </div>
                                {props.children}
                            </div>
                        </div>
                    </div>
                    </Page.Content>
                </div>
            </div>
            <SiteFooter />
        </Page.Main>
    </Page>

};

