import * as React from "react";
import { Formik } from "formik";
import { Link} from "react-router-dom";
import FormCard from "./utils/FormCard";
import { FormTextInput, Grid} from "tabler-react";
import {StandaloneFormPage} from "./utils/StandaloneFormPage";
import FormPasswordInput from "./utils/FormPasswordInput";

import api from "../api";
import {LoadingIndicator} from "./utils/LoadingIndicator";

import Utils from "../utils";
let logoImage = Utils.siteLogo;

class PasswordReset extends React.Component {

    state = {
        loggedIn: false
    }

    processForm = (values, setSubmitting) => {

        setSubmitting( true );

        api.post( "/auth/reset-password", {uuid: this.state.uuid, newPassword: values.newPassword}).then(response => {

            this.setState( {resetComplete: true});

        }).catch( err => {
            this.setState( {errorMessage :  err.response.data.message || "Request failed"});
        }).finally(() => {
            setSubmitting(false);
        });

    }

    constructor(props) {
        super(props);

        const {uuid} = this.props.match.params;

        api.get( "/auth/password-reset/" + uuid).then( response => {

            // link is good; prepare the reset.
            //
            let email = response.data.reset.email;

            // Redact the email address
            //
            console.log( email );console.log(email.length);
            let redacted = email.substring(0, 4) + "*********" + email.substr(email.length - 8,8);
            this.setState( {redactedEmail: redacted, email:email, uuid: uuid});

        }).catch(err=> {
           console.error(err.message);
        });

    }
    componentWillMount() {

    }

    render()
    {
        return (
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validate={values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors = {};

                    if (!values.newPassword) {
                        errors.newPassword = "Required";
                    }

                    if( !values.passwordConf) {
                        errors.passwordConf = "Required";
                    }

                    if( values.newPassword !== values.passwordConf)
                    {
                        errors.passwordConf = "Must match password exactly";
                    }

                    return errors;
                }}
                onSubmit={(
                    values,
                    {setSubmitting, setErrors /* setValues and other goodies */}
                ) => {

                    this.processForm( values, setSubmitting );

                }}
                render={({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                         }) => (

                    <StandaloneFormPage formClass="anythingElse" imageURL={logoImage} imageAlt="Embedded Intelligence">

                        {!this.state.uuid ?
                            <LoadingIndicator/>
                        :
                        (
                            this.state.resetComplete ?
                                <>
                                    <p style={{textAlign:"center"}}>
                                        Your password has been reset.
                                    </p>
                                    <p style={{textAlign:"center"}}>
                                        <Link to="/login">Login to your account.</Link>
                                    </p>
                                </>
                                :
                                <>
                                    <p style={{textAlign:"center"}}>
                                        Enter your new password for the account with the email address <strong>{this.state.redactedEmail }</strong>
                                    </p>

                                    <Grid.Col md={4} offsetMd={4}>
                                        <FormCard
                                            buttonText="Reset password"
                                            title="Password reset"
                                            onSubmit={handleSubmit}
                                            isSubmitting={isSubmitting}
                                        >

                                            {this.state.errorMessage &&
                                            <p style={{color:"red"}}>{this.state.errorMessage}</p>
                                            }


                                            <FormPasswordInput
                                                name="newPassword"

                                                label="New password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.newPassword}
                                                error={errors && errors.newPassword}
                                            />

                                            <FormTextInput
                                                name="passwordConf"
                                                type="password"
                                                label="Repeat password"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.passwordConf}
                                                error={errors && errors.passwordConf}
                                            />

                                        </FormCard>
                                    </Grid.Col>
                                </>
                            )
                        }

                    </StandaloneFormPage>

                )}
            />
        );
    }
}

export default PasswordReset;
