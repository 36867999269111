import React from 'react';
import {Switch, Route, NavLink, withRouter} from "react-router-dom";

import {
    Page,
    Nav
} from "tabler-react";

import UserInvites from "./UserInvites";
import UserInvite from "./UserInvite";
import Dashboard from "./Dashboard";
import CompanyIndex from "./CompanyIndex";

class AdminWrapper extends React.Component {

    render() {

        const { match } = this.props;

        return <>

            <Page.Header
                title={"Admin"}
            />

            <Nav className={"hoisted-nav"}>

                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)} to={`${match.url}`}>Dashboard</Nav.Item>
                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)}  to={`${match.url}/user-invites`}>User invites</Nav.Item>
                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)}  to={`${match.url}/suppliers`}>Company index</Nav.Item>

            </Nav>

            <Switch>
                <Route exact path={`${match.path}`} component={Dashboard} />

                <Route path={`${match.path}/user-invites`} component={UserInvites} />
                <Route path={`${match.path}/invite`} component={UserInvite} />
                <Route path={`${match.path}/suppliers`} component={CompanyIndex} />

            </Switch>

        </>
    }

}

export default AdminWrapper;
