import React from "react";
import Loader from 'react-loader-spinner';

export const LoadingIndicator = props => (
    <Loader
        type="TailSpin"
        color="#00BFDF"
        height="50"
        width="50"
    />
);

export const SmallLoadingIndicator = props => (
    <Loader
        type="TailSpin"
        color="#00BFDF"
        height="25"
        width="25"
    />
);
