import React from "react";

import {LoadingIndicator} from "../utils/LoadingIndicator";

import {
    Card,
    Grid
} from "tabler-react";

import api from "../../api";

import {withSnackbar} from "notistack";

import C3Chart from "../utils/C3Chart";

const QuickChart = (props) => (
    <C3Chart
        data={{
            columns:
                // each columns data
                props.data
            ,
            type: "pie", // default type of chart
            colors: {
                data1: "#3982cd",
            }
        }}
        size={{height:400}}
        padding={{
            bottom: 0,
            top: 0,
        }}

    />
);

class CompanyFocus extends React.Component {

    state = {

    }

    componentWillMount() {

        const { id } = this.props.match.params;

        api.get( "/suppliers/" + id + "/keyword-analysis", {}).then((response) => {

            const {data} = response.data;
            let foci = {};
            if( data )
            {
                // Sort by most interesting first
                //
                //const sorted = keywords.sort( (x,y) => x.Count < y.Count ? 1 : -1);
                //console.log(sorted);
                //this.setState({keywords: sorted.slice(0, 10)});

                // Cut all categories down ot the top 10
                //
                ["stack", "vendor", "industry", "solution" ].forEach( interest => {

                    let vals = data[interest];

                    if( vals )
                    {
                        if( vals.length > 10)
                        {
                            // Split into top 10 and 'other'
                            let topTen = vals.slice(0,9);
                            let remainder = vals.slice(9);

                            let other = remainder.reduce((c, r) => c + parseFloat(r.score), 0 );
                            topTen.push( {description: "Other", score: other});

                            foci[interest] = topTen;
                        }
                        else
                            foci[interest] = vals; //.slice(0,10);
                    }

                });


                this.setState({foci});
            }

        });

    }

    render() {

        if( !this.state.foci)
            return <LoadingIndicator/>;

        return <>

            <Grid.Row>

                <Grid.Col md={6}>

                    <Card title="Tech category focus">
                        <Card.Body>
                            <QuickChart
                                data = {this.state.foci.stack.map(focus => [focus.description, focus.score])}
                            />
                        </Card.Body>
                    </Card>

                </Grid.Col>

                <Grid.Col md={6}>

                    <Card title="Vendor focus">
                        <Card.Body>
                            <QuickChart
                                data = {this.state.foci.vendor.map(focus => [focus.description, focus.score])}
                            />
                        </Card.Body>
                    </Card>

                </Grid.Col>

                <Grid.Col md={6}>

                    <Card title="Industry focus">
                        <Card.Body>
                            <QuickChart
                                data = {this.state.foci.industry.map(focus => [focus.description, focus.score])}
                            />
                        </Card.Body>
                    </Card>

                </Grid.Col>

                <Grid.Col md={6}>

                    <Card title="Solution focus">
                        <Card.Body>
                            <QuickChart
                                data = {this.state.foci.solution.map(focus => [focus.description, focus.score])}
                            />
                        </Card.Body>
                    </Card>

                </Grid.Col>

            </Grid.Row>

        </>
    }

}

export default withSnackbar(CompanyFocus);
