import React, { useEffect, useRef } from "react";

//import c3, { ChartAPI, ChartConfiguration, Data } from "c3";
import c3 from "c3";

const C3Chart = function(props) {

    const chart = useRef(null);
    const chartRef = useRef(null);
    const prevPropsRef = useRef(props);

    const destroyChart = function() {
        try {
            if (chart.current) {
                chart.current.destroy();
                chart.current = null;
            }
        } catch (err) {
            throw new err();
        }
    };

    const generateChart = function(config) {
        const newConfig = Object.assign({ bindto: chartRef.current }, config);
        return c3.generate(newConfig);
    };

    const loadNewData = function(data) {
        if (chart.current) {
            chart.current.load(data);
        }
    };

    const unloadData = function() {
        if (chart.current) {
            chart.current.unload();
        }
    };

    useEffect(() => {
        const updateChart = function(config) {
            if (!chart.current) {
                chart.current = generateChart(config);
            }

            if (props.unloadBeforeLoad) {
                unloadData();
            }

            loadNewData(config.data);
        };

        updateChart(props);
        if (props.onPropsChanged) {
            props.onPropsChanged(prevPropsRef.current, props, chart);
        }
        prevPropsRef.current = props;
        return () => {
            destroyChart();
        };
    }, [props]);

    const className = props.className || "";
    const style = props.style || {};

    return <div ref={chartRef} className={className} style={style} />;
};

export default C3Chart;
