import React from 'react';

import {Button} from "@material-ui/core";
import {Icon} from "tabler-react";

import Utils from "../../utils";

export const SubmitButton = (props) => {
    return <Button {...props} type="submit" variant="contained" color="primary">{props.label || "Submit"}</Button>;
};

export const SaveButton = (props) => {
    return <Button {...props} variant="contained" color="primary">{props.label || "Save"}</Button>;
};

export const DeleteButton = (props) => {
    return <Button {...props} variant="contained" color="primary" style={{backgroundColor: Utils.themeColours.negativeAction}}>{props.label || "Delete"}</Button>;
};

export const SmallDeleteButton = (props) => {
    return <Button {...props} color="primary" style={{color: Utils.themeColours.negativeAction}}><Icon prefix="fe" name="trash" /></Button>;
};

export const GotoButton = (props) => {
    return <Button {...props} color="primary" style={{color: Utils.themeColours.neutralAction}}><Icon prefix="fe" name="crosshair" /></Button>;
};

export const BackButton = (props) => {
    return <Button {...props} >{props.label || "Back"}</Button>;
};

export const ActivateButton = (props) => {
    return <Button {...props} variant="contained" color="primary" style={{backgroundColor: Utils.themeColours.positiveAction}}>{props.label || "Activate"}</Button>;
};

export const SuspendButton = (props) => {
    return <Button {...props} variant="contained" color="primary" style={{backgroundColor: Utils.themeColours.negativeAction}}>{props.label || "Suspend"}</Button>;
};
