import {Form} from "tabler-react";
import ReactPasswordStrength from "react-password-strength";
import * as React from "react";

const FormPasswordInput = props => {

    //
    // Change handler intercept. ReactPasswordStrength component renders only a standard <input>, but then captures
    // changes and passes its own parameters around, while Formik expects to receive the same 'e' Event data from the original event.
    // So we trap the custom event and make a noddy reconstruction of the 'e' Event parameter with the things Formik needs
    // to do its business.
    //
    const handleChange = (state, result) => {

        if( props.onChange) {
            let e = {target: {name: props.name || "password", value: state.password}};
            console.log( e );
            props.onChange(e);
        }
    };

    const errors = props.error && "is-invalid state-invalid";
    return <Form.Group label={props.label}>

        <ReactPasswordStrength
            minLength={8}
            minScore={2}
            scoreWords={['weak', 'okay', 'good', 'strong', 'stronger']}
            changeCallback={handleChange}
            inputProps={{ name: props.name || "password", autoComplete: "off", className: "form-control " + errors, ...props }}
        />
        {props.error && <span className="invalid-feedback" style={{display:"block"}}>{props.error}</span>}
    </Form.Group>
        ;

};
export default FormPasswordInput;
