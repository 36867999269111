import {green, red, blue } from "@material-ui/core/colors";

import dayjs from "dayjs";
import {Config} from "./config";

const siteLogo = require( "./assets/ei-logo-color-sm.png");


const Utils = {

    siteLogo: siteLogo,

    Formatting: {
        SqlTimestamp: "YYYY-MM-DD HH:mm:ss"
    },

    stockStyles: {
        tighterTable: {
            "& td": {
                padding: "4px 16px 4px 10px"
            },
            "& th": {
                padding: "4px 16px 4px 10px"
            }
        },
        thumbnail: {
            width: "60px",
            height: "40px",
            position: "relative",
            overflow: "hidden",
            alignItems: "center",
            flexShrink: 0,
            justifyContent: "center",
            "& img" : {
                width: "auto",
                height: "auto",
                maxHeight: "40px",
                objectFit: "cover"
            },
            marginTop: "0px",
            marginBottom: "0px"
        }

    },

    themeColours: {
        positiveAction: green.A700,
        neutralAction: blue.A700,
        negativeAction: red.A700
    },

    tidyLongDate: function(dt, def) {
        return dt ? Utils.formatLongDate(dt) : def;
    },

    formatLongDate: function(dt) {
        return( dayjs(dt).format(Config.dateLongFormat));
    },

    formatShortDate: function(dt, invalidText) {
        let d = dayjs(dt);
        if( d.isValid())
            return(d.format(Config.shortDateFormat));
        return(invalidText || "Invalid date");
    },

    formatDateTime: function(dt) {
        return( dayjs(dt).format(Config.dateTimeFormat));
    },

    format24Hr: function(dt) {
        return( dayjs(dt).format("HH:mm:ss"));
    },
    formatThousands: function(num) {
        return num && num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },


    formatCurrency: function(num) {
        let f = parseFloat(num);
        return isNaN(f) ? "-" :  parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    },

    formatLatLngDegrees: function (dbl) {
        return(dbl && dbl.toFixed ? dbl.toFixed(5) : "---");
    },

    formatBytes: function(num) {
        let b = parseInt(num);
        let suf = "B";
        if( !isNaN(b))
        {
            if( b > 1024 )
            {
                b /= 1024;
                suf = "KB";
            }
            if( b > 1024 )
            {
                b /= 1024;
                suf = "MB";
            }
            if( b > 1024 )
            {
                b /= 1024;
                suf = "GB";
            }
            return( (suf === "B" ? b : b.toFixed(2) ) + " " + suf);
        }
        return("-");
    },


    validators: {
        email: function(v) {
            return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,5}$/i.test(v);
        }
    },

    timestamp: () => {

        return(
            dayjs().format(Utils.Formatting.SqlTimestamp)
        );

    },


}

export default Utils;
