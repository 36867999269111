import React from "react";

import {LoadingIndicator} from "../utils/LoadingIndicator";

import {
    Card,
    Grid
} from "tabler-react";

import api from "../../api";

import {withSnackbar} from "notistack";

class WordCloud extends React.Component {

    state = {

    }

    componentWillMount() {

        const { id } = this.props.match.params;

        api.get( "/assets/" + id + "/wordCloud", { responseType:"blob" }).then((response) => {

            const reader = new FileReader();

            console.log("Image results:", response);

            reader.onloadend = (img) => this.setState({imageData: reader.result});
            reader.readAsDataURL(response.data);


        }).catch(err => {

            this.setState({ message: "No Word Cloud for this supplier."});

        });

    }

    render() {

        return <>

            <Grid.Row>

                <Grid.Col>

                    <Card>
                        <Card.Body>

                            { this.state.imageData ?
                                <img src={this.state.imageData} alt={"Word cloud"} />
                            :
                                this.state.message ?
                                    <p>{this.state.message}</p>
                                    :
                                    <LoadingIndicator/>

                            }

                        </Card.Body>
                    </Card>

                </Grid.Col>
            </Grid.Row>

        </>
    }

}

export default withSnackbar(WordCloud);
