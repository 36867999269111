import React from "react";
import axios from "axios";
import Utils from "../../utils";
import api from "../../api";
import dayjs from "dayjs";

import {LoadingIndicator} from "../utils/LoadingIndicator";

import {
    Card,
    Grid,
    Table
} from "tabler-react";

import {withSnackbar} from "notistack";

import {renderMonthByName,renderCoHouseShareholderType, renderCoHouseTitle, renderCompanyType} from "../utils/Renderers";

const renderAccountsDue = (date) => {

    const formatted = Utils.formatShortDate(date);

    return <span className={dayjs(date).isBefore(dayjs()) ? "oooh-bad" : ""}>{formatted}</span>;

}

const makeAddress = (company) => {

    let a = [company.RegAddressCareOf +  " "+company.RegAddressAddressLine1,
        company.RegAddressAddressLine2,
        company.RegAddressPostTown,
        company.RegAddressCounty,
        company.RegAddressPostCode
    ].filter(r => r && r.length );

    return a.map( a => ( <>{a}<br/></> ) );

}

const listSICCodes = (company) => {

    let codes = [<p>{company.SICCode1}<br/><span className="small text-secondary">{company.SICCode1Text}</span></p>];

    if( company.SICCode2 )
        codes.push( <p>{company.SICCode2}<br/><span className="small text-secondary">{company.SICCode2Text}</span></p> );

    if( company.SICCode3 )
        codes.push( <p>{company.SICCode3}<br/><span className="small text-secondary">{company.SICCode3Text}</span></p> );

    if( company.SICCode4 )
        codes.push( <p>{company.SICCode4}<br/><span className="small text-secondary">{company.SICCode4Text}</span></p> );

    return(codes);

}

const makeCompanyStatus = (status) => {

    return( status.charAt(0).toUpperCase() + status.slice(1));

}

class CompaniesHouse extends React.Component {

    state = {

    }

    componentWillMount() {

        this.reload();

        //this.setState({property: this.props.property});
    }

    reload = () => {

        const { id } = this.props.match.params;

        api.get( "/suppliers/" + id + "/companies-house", {}).then((response) => {

            const {company} = response.data;

            if( company )
            {
                let url = "https://nominatim.openstreetmap.org/search?q=" + company.RegAddressPostCode + "&format=json&polygon=1&addressdetails=1";

                axios.get( url ).then( res => {

                    let first = res.data[0];

                    company.lat = parseFloat(first.lat);
                    company.lng  = parseFloat(first.lon);

                    this.setState({company: company});

                }).catch( err => {
                    console.log("Failed to fetch lat/lng from postcode", err.message);
                })



            }

        });


    }

    render() {

        const {company} = this.state;

        if( !company )
            return <LoadingIndicator/>;

        return <>

            <Grid.Row>

                <Grid.Col md={7}>

                    <Card title={"General information"}>
                        <Card.Body>

                            <Table
                                responsive
                                highlightRowOnHover
                                hasOutline

                                cards
                                className="text-nowrap"
                            >
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Col>
                                            Type
                                        </Table.Col>
                                        <Table.Col>
                                            {renderCompanyType(company.CompanyCategory)}
                                        </Table.Col>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Col>
                                            Registered address
                                        </Table.Col>
                                        <Table.Col>
                                            {makeAddress(company)}
                                        </Table.Col>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Col>
                                            Company status
                                        </Table.Col>
                                        <Table.Col>
                                            {makeCompanyStatus(company.CompanyStatus)}
                                        </Table.Col>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Col>
                                            Incorporated since
                                        </Table.Col>
                                        <Table.Col>
                                            {Utils.formatShortDate(company.IncorporationDate)}
                                        </Table.Col>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Col>
                                            Year end
                                        </Table.Col>
                                        <Table.Col>
                                            {renderMonthByName(company.YearEndMonth)}
                                        </Table.Col>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Col>
                                            Last accounts
                                        </Table.Col>
                                        <Table.Col>
                                            {Utils.formatShortDate(company.AccountsLastMadeUpdate)}
                                        </Table.Col>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Col>
                                            Accounts due
                                        </Table.Col>
                                        <Table.Col>
                                            {renderAccountsDue(company.AccountsNextDueDate)}
                                        </Table.Col>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Col>
                                            SIC Code(s)
                                        </Table.Col>
                                        <Table.Col>
                                            {listSICCodes(company)}
                                        </Table.Col>
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Card.Body>
                    </Card>
                </Grid.Col>

                <Grid.Col md={5}>

                    <Card title="Directors">
                        <Card.Body>

                            <Table
                                headerItems={[
                                    { content: "Name/nationality" },
                                    { content: "Role/Since" },
                                ]}

                            >
                                {company.directors.map (director =>

                                    <Table.Row key={director.name}>
                                        <Table.Col>
                                            {director.name}
                                            <br/>
                                            <span className="small text-secondary">{director.nationality}</span>
                                        </Table.Col>

                                        <Table.Col>
                                            {renderCoHouseTitle(director.role)}
                                            <br/>
                                        <span className="small text-secondary">{Utils.formatShortDate(director.appointed)}</span>
                                        </Table.Col>
                                    </Table.Row>

                                )}
                            </Table>
                        </Card.Body>
                    </Card>

                    <Card title="Shareholders">
                        <Card.Body>

                            <Table
                                headerItems={[
                                    { content: "Name" },
                                    { content: "Recorded" },
                                ]}

                            >
                                {company.shareholders.map (shareholder =>

                                    <Table.Row key={shareholder.name}>
                                        <Table.Col>
                                            {shareholder.name}<br/>
                                            <span className="small text-secondary">{renderCoHouseShareholderType(shareholder.kind)}</span>
                                        </Table.Col>

                                        <Table.Col>
                                            {Utils.formatShortDate(shareholder.recordDate)}
                                        </Table.Col>
                                    </Table.Row>

                                )}
                            </Table>
                        </Card.Body>
                    </Card>


                </Grid.Col>



            </Grid.Row>

        </>
    }

}

export default withSnackbar(CompaniesHouse);
