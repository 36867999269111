import * as React from "react";
import { Formik } from "formik";
import {Link, Redirect} from "react-router-dom";

import {Form, FormCard, Grid, Card, FormTextInput} from "tabler-react";
import {StandaloneFormPage} from "./utils/StandaloneFormPage";
import api from "../api";

import {Config} from "../config";

import FormPasswordInput from "./utils/FormPasswordInput";
import Auth from "../auth";
import {LoadingPage} from "./utils/LoadingPage";

import LoadingOverlay from "react-loading-overlay";

import Utils from "../utils";
let logoImage = Utils.siteLogo;

class RegisterPage extends React.Component {

    state = {
        complete: false,
        redirectTo: null,
        errorMessage: null,
        invite: null,
        registrant: null
    }

    componentDidMount() {

        const {uuid} = this.props.match.params;

        const blankRegistrant = {
            email: "",
            password: "",
            passwordConf: "",
            title: "",
            firstName: "",
            lastName: "",
            companyName: "",
            website: "",
            role: ""
        };

        if( uuid )
        {
            api.get( "/auth/invite/" + uuid).then( response => {

                if( response.data.expired )
                    this.setState({rejectReason: "expired"});

                if( response.data.noInvite )
                    this.setState({rejectReason: "invite-required"});

                let invite = response.data.invite;

                if( invite ) {
                    let reg = Object.assign(blankRegistrant, invite);

                    this.setState({registrant: reg});
                }

            }).catch(err => {
                //this.setState( {rejectReason: "invite-required"});
            });
        }
        else {

            // No invitation, set empty form data.
            //
            this.setState( {registrant: blankRegistrant});
        }

    }

    processForm = (values, setSubmitting) => {

        //
        // Check stuff here...
        //

        setSubmitting( true );

        this.setState( {errorMessage :null});

        api.post( "/auth/register", {

            params: values

        }).then((response) => {

            let auth = new Auth();
            auth.logout();

            //
            // TODO. Show message to confirm email? Redirect to the portal?
            //
            // Are we part-way through registering a property? We should record that now.
            //
            // etc...
            //
            this.setState({complete: true});

        }).catch((err) => {

            console.error( err );
            this.setState( {errorMessage: (err.response ? err.response.data.message : err.message) });

        }).finally(() => {

            setSubmitting(false);

        });

    }

    render() {
        if (this.state.redirectTo === "login")
            return <Redirect to={"/login"}/>;

        if( this.state.redirectTo === "payment")
            return <Redirect to={"https://buy.stripe.com/aEU5og62A29F2643cc?prefilled_email=" + this.state.registrant.email} />;

        if (!this.state.registrant && !this.state.rejectReason)
            return <LoadingPage/>;

        if (this.state.rejectReason)
        {
            return <StandaloneFormPage imageURL={logoImage}>
                <Card
                    title="Embedded Intelligence registration"
                >
                    <Card.Body>
                        <p>The Embedded Intelligence Directory is currently in a closed private beta period, and requires an invitation to register.</p>
                        {this.state.rejectReason === "expired" &&
                        <p>This invitation link has expired or has already been used.</p>
                        }
                        <p>If you would like to participate in our beta programme, please <a href="https://www.embedded-it.co.uk/contact-8">contact us</a></p>
                    </Card.Body>
                </Card>

                <p style={{textAlign:"center"}}>
                    <Link to="/login">Already registered? Login here</Link>
                </p>

            </StandaloneFormPage>
                ;
        }

        if( this.state.complete )
        {
            return <StandaloneFormPage imageURL={logoImage}>
                <FormCard
                    buttonText="Buy now"
                    title="Registration complete"
                    onSubmit={() => {
                        //this.setState({redirectTo:"payment"})
                        window.location.assign("https://buy.stripe.com/aEU5og62A29F2643cc?prefilled_email=" + this.state.registrant.email);
                    } }
                >
                    <p>Welcome! Thank you for registering.</p>
                    <p>Click below to proceed to our payment page to purchase your licence and start receiving your personalised news feed.
                    </p>
                </FormCard>

            </StandaloneFormPage>
            ;
        }

        return (
            <Formik

                enableReinitialize={true}

                initialValues={this.state.registrant}

                validate={values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors = {};

                    if (!values.email) {
                        errors.email = "Required";
                    } else if (
                        !Utils.validators.email(values.email)
                    ) {
                        errors.email = "Invalid email address";
                    }

                    if (!values.title) {
                        errors.title = "Required";
                    }
                    if (!values.firstName) {
                        errors.firstName = "Required";
                    }
                    if (!values.lastName) {
                        errors.lastName = "Required";
                    }

                    if (!values.companyName) {
                        errors.companyName = "Required";
                    }
                    if (!values.website) {
                        errors.website = "Required";
                    }

                    if (!values.password) {
                        errors.password = "Required";
                    }

                    if( !values.passwordConf) {
                        errors.passwordConf = "Required";
                    }

                    if( values.password !== values.passwordConf)
                    {
                        errors.passwordConf = "Must match password exactly";
                    }

                    if( !values.termsAccepted )
                        errors.termsAccepted = "Required";

                    return errors;
                }}
                onSubmit={(
                    values,
                    {setSubmitting, setErrors /* setValues and other goodies */}
                ) => {

                    this.processForm( values, setSubmitting );

                }}
                render={({
                     values,
                     errors,
                     touched,
                     handleChange,
                     handleBlur,
                     handleSubmit,
                     isSubmitting,
                 }) => (

                    <LoadingOverlay
                        active={isSubmitting}
                        fadeSpeed={300}
                        spinner
                        styles={{
                            overlay: (base) => ({
                                ...base,
                                background: 'rgba(245, 247, 251, 0.5)'
                            }),
                            spinner: (base) => ({
                                ...base,
                                '& svg circle': {
                                    stroke: 'rgba(100, 100, 110, 0.5)'
                                }
                            })

                        }
                        }>
                        <StandaloneFormPage imageURL={logoImage} formClass="standalone-form-double-width">

                            <p style={{textAlign:"center"}}>
                                Welcome to Embedded Intelligence!
                            </p>
                            <p style={{textAlign:"center"}}>
                                Complete the form below to create your account.
                            </p>

                            <p style={{textAlign:"center"}}>
                                <Link to="/login">Already registered? Login here</Link>
                            </p>

                            <Grid.Row>

                            <Grid.Col lg={6} offsetLg={3} md={6} sm={12} >
                            <FormCard
                                buttonText="Register"
                                title="Complete your registration"
                                onSubmit={handleSubmit}
                            >

                                <Grid.Row>
                                    <Grid.Col sm={2}>
                                        <FormTextInput
                                            name="title"
                                            label="Title"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.title}
                                            error={errors && errors.title}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={10}>
                                        <FormTextInput
                                            name="firstName"
                                            label="First name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.firstName}
                                            error={errors && errors.firstName}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                                <FormTextInput
                                    name="lastName"
                                    label="Last name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.lastName}
                                    error={errors && errors.lastName}
                                />

                                <FormTextInput
                                    name="email"
                                    label="Email address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.email}
                                    error={errors && errors.email}
                                />

                                <FormTextInput
                                    name="phone"
                                    label="Telephone number"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.phone}
                                    error={errors && errors.phone}
                                />

                                <FormTextInput
                                    name="companyName"
                                    label="Company name"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.companyName}
                                    error={errors && errors.companyName}
                                />

                                <FormTextInput
                                    name="website"
                                    label="Company website"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.website}
                                    error={errors && errors.website}
                                />

                                <FormTextInput
                                    name="role"
                                    label="Job title"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.role}
                                    error={errors && errors.role}
                                />


                                <FormPasswordInput
                                    name="password"
                                    type="password"
                                    label="Password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.password}
                                    error={errors && errors.password}
                                />

                                <FormTextInput
                                    name="passwordConf"
                                    type="password"
                                    label="Repeat password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    value={values && values.passwordConf}
                                    error={errors && errors.passwordConf}
                                />


                                <Form.Checkbox
                                    checked={values && values.termsAccepted}
                                    name="termsAccepted"
                                    label={<span>I have read and agree to the <a href="https://embedded-intelligence.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer" >terms and conditions</a> of use</span>}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />

                                {errors && errors.termsAccepted &&
                                <p className="standalone-error invalid-feedback" style={{display:"block"}}>{errors.termsAccepted}</p>
                                }

                                {this.state.errorMessage &&
                                <p style={{color:"red"}}>{this.state.errorMessage}</p>
                                }

                            </FormCard>
                            </Grid.Col>
                            </Grid.Row>
                        </StandaloneFormPage>
                    </LoadingOverlay>
                )}
            />
        );
    }
}

export default RegisterPage;
