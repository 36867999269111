import React from "react";

import {LoadingIndicator, SmallLoadingIndicator} from "../utils/LoadingIndicator";

import {
    Button,
    Card, FormTextInput,
    Grid, Table
} from "tabler-react";

import api from "../../api";

import {withSnackbar} from "notistack";
import Utils from "../../utils";

import {SmallDeleteButton} from "../utils/Buttons";
import {Formik} from "formik";

class NewsFeed extends React.Component {

    state = {

    }

    reload = () => {

        api.get( "/feeds/news").then((response) => {

            this.setState({newsFilters: response.data.newsFilters});

        }).catch(err => {

            console.error(err);

        });

    }

    deleteItem = (keyword) => {

        api.delete( "/feeds/news", {params: {keyword}}).then((response) => {

            this.reload();

        }).catch(err => {

            console.error(err);

        });

    }

    processForm = async (values, {setValues}) => {

        const {keyword} = values;
        this.setState({runningQuery: true});

        api.post("/feeds/news", {keyword} ).then( res => {

            setValues({keyword:""});
            this.reload();

        }).finally(() => {
            this.setState( {runningQuery: false });
        });

    }

    componentWillMount() {

        this.reload();

    }

    render() {

        const {newsFilters} = this.state;
        if( !newsFilters)
            return <LoadingIndicator />;

        return <>

            <Grid.Row>

                <Grid.Col md={7} sm={12}>

                    <Card title={"My news feed"}>

                        <Card.Body>

                            <Formik

                                enableReinitialize={true}
                                initialValues={{keyword:""}}

                                validate={values => {
                                    // same as above, but feel free to move this into a class method now.
                                    let errors = {};

                                    if (!values.keyword) {
                                        errors.keyword = "Required";
                                    }

                                    return errors;
                                }}

                                onSubmit={this.processForm}

                                render={({
                                             values,
                                             errors,
                                             touched,
                                             handleChange,
                                             handleBlur,
                                             handleSubmit,
                                             isSubmitting,
                                         }) => (

                                    <>

                                        <Grid.Row>

                                            <Grid.Col md={6}>

                                                <FormTextInput
                                                    label={null}
                                                    name="keyword"
                                                    placeholder="Enter keyword or phrase"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    onKeyPress={(e)=>{
                                                        if( e.key === "Enter")
                                                            handleSubmit();
                                                    }}
                                                    value={values && values.keyword}

                                                />
                                            </Grid.Col>

                                            <Grid.Col md={3}>

                                                <Button type="submit" color="primary" onClick={handleSubmit} >Add to feed</Button>
                                            </Grid.Col>

                                            <Grid.Col md={1}>
                                                { this.state.runningQuery && false && <SmallLoadingIndicator />}
                                            </Grid.Col>

                                        </Grid.Row>
                                    </>
                                )}
                            />

                            {newsFilters.length ?

                                <Table
                                    headerItems={[
                                        {content: "Keyword/phrase"},
                                        {content: "Last featured"},
                                    ]}
                                >
                                    {newsFilters.map(filter =>

                                        <Table.Row key={filter.keyword}>
                                            <Table.Col>
                                                {filter.keyword}
                                            </Table.Col>

                                            <Table.Col>
                                                <span
                                                    className="small text-secondary">{Utils.formatShortDate(filter.lastSent, "Never")}</span>
                                            </Table.Col>

                                            <Table.Col>
                                                <SmallDeleteButton onClick={() => {
                                                    this.deleteItem(filter.keyword);
                                                }}/>
                                            </Table.Col>

                                        </Table.Row>
                                    )}
                                </Table>
                                :
                                <>
                                    <p>You don't have any news filters setup yet. Add your first one!</p>
                                </>

                            }

                        </Card.Body>
                    </Card>
                </Grid.Col>

                <Grid.Col md={5} sm={12}>

                    <Card title={"Tips"}>

                        <Card.Body>

                            <p>
                                Please enter the keywords that will be used to search all of the monitored news feeds for your daily curated news feed.
                            </p>
                            <p>
                                The search is looking for specific keywords, so make them as broad as possible, for instance “Dell” is more likely to get a hit than “Dell PCs”.
                            </p>
                            <p>
                                Also, the search is an exact match, so spelling mistakes or rogue spaces might hinder the keyword matching process.
                            </p>

                        </Card.Body>
                    </Card>
                </Grid.Col>

            </Grid.Row>

        </>
    }

}

export default withSnackbar(NewsFeed);
