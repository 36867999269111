import React from 'react';
import {Switch, Route, NavLink, withRouter} from "react-router-dom";
import Auth, {withUserInfo} from "../../auth";
import api from "../../api";

import {
    Page,
    Nav
} from "tabler-react";

import AccountDetails from "./AccountDetails";
import NewsFeed from "./NewsFeed";
import BuyLicence from "./BuyLicence";
import {LoadingIndicator} from "../utils/LoadingIndicator";


class AccountWrapper extends React.Component {

    state = {}

    componentDidMount() {

        api.userReady((user) => {
            this.setState({user});
        });

    }

    render() {

        const { match } = this.props;

        const {user} = this.state;

        return <>

            <Page.Header
                title={"Account"}
            />

            <Nav className={"hoisted-nav"}>

                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)}  to={`${match.url}`}>Personalised news feed</Nav.Item>
                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)} to={`${match.url}/details`}>Account details</Nav.Item>

            </Nav>

            <Switch>
                <Route path={`${match.path}/details`} component={AccountDetails} />
                <Route path={`${match.path}`} component={user ? (Auth.isValidLicence(user) ? NewsFeed : BuyLicence) : LoadingIndicator} />

            </Switch>

        </>
    }

}

export default withUserInfo(AccountWrapper);
