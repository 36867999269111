import React from "react";
import LoadingOverlay from "react-loading-overlay";

export const StockLoadingOverlay = props => (

     <LoadingOverlay
        active={props.busy}
        fadeSpeed={300}
        spinner
        styles={{
            overlay: (base) => ({
                ...base,
                background: 'rgba(245, 247, 251, 0.5)'
            }),
            spinner: (base) => ({
                ...base,
                '& svg circle': {
                    stroke: 'rgba(100, 100, 110, 0.5)'
                }
            })

        }
        }>
        {props.children}
    </LoadingOverlay>

);

