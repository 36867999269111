//
// Local dev configuration - GCloud will set process.env.* from lib.yaml settings for us online whether dev or production
//

export const Config = {

    apiEndpoint: (() => {

        let host = window.location.hostname;

        if( host.startsWith("192"))
            return( "http://" + host + ":4000");

        if( host.startsWith("localhost"))
            return ("http://localhost:4000");

        return ("/api");

    })(),

    sysName: (() => {

        let host = window.location.hostname;
        if( host.startsWith("embedded-intelligence"))
            return( "");
        return ("localdev");

    })(),

    apiKey: "1SDVSA_2344236f2345fcyg15fqw4gfacsetav5r!",
    imageLocation: "/uploads/",
    dateLongFormat: "ddd, DD MMM YYYY",
    shortDateFormat: "DD MMM YYYY",
    dateTimeFormat: "ddd, DD MMM YYYY, HH:mm:ss",
    portalTermsUri: "https://www.embedded-it.co.uk/terms-and-conditions"
}


