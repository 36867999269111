import React from "react";

import {LoadingIndicator} from "../utils/LoadingIndicator";

import {
    Grid, PricingCard
} from "tabler-react";

import api from "../../api";
import {withUserInfo} from "../../auth";

import {withSnackbar} from "notistack";

import C3Chart from "../utils/C3Chart";

import {renderValueOrNull, renderMonetaryValue, renderNullAsNA} from "../utils/Renderers";
import {Link} from "react-router-dom";

const renderTidyTrend = (trend) => {
    return( trend === "TrendNotAvailable" ? "n/a" : trend);
}

const RenderRaw = (props) => {

    const data = props.data;
    if( !data )
        return null;

    return <table>

        {Object.keys(data).map( i =>
            <tr><td>{i}</td><td>{data[i]}</td></tr>
        )}

    </table>;


}

class CompanyFinancials extends React.Component {

    state = {

    }

    componentWillMount() {

        const { id } = this.props.match.params;

        api.get("/suppliers/" + id + "/financials").then((response) => {

            this.setState({financialsLoaded: true, financials: response.data.financials, dataset:response.data.dataset});

        });

        api.get("/suppliers/" + id + "/rating-history").then((response) => {

            this.setState({ratingHistory: response.data.series});

        });

    }

    render() {

        const data = this.state.financials;

        if( !this.state.financialsLoaded)
            return <LoadingIndicator/>

        if( !data)
            return <>
                <p className={"text-center"} style={{marginTop: "10rem", marginBottom: "6rem"}}><h4>Detailed financial data is not available for this organisation.</h4></p>
            </>;

        return <>

            {this.state.dataset === "demo" && <>
                <div className={"demo-warning"}>This financial information is just a sample. <Link to={"/buy"}>Click here to see your upgrade options</Link></div>
            </>}

            <Grid.Row>

                <Grid.Col sm={4}>
                    <PricingCard>

                            <PricingCard.Category>Companies House Status</PricingCard.Category>

                            <div style={{fontSize: "3.0rem", marginBottom: "15px"}}>
                                <span className={"goodness"}><i className={"fe fe-check-circle"} /></span>
                            </div>

                            <div style={{fontSize: "1.5rem"}}>
                                {data.companyStatusDesc}
                            </div>

                    </PricingCard>
                </Grid.Col>


                <Grid.Col sm={4}>
                    <PricingCard>

                            <PricingCard.Category>Credit Rating</PricingCard.Category>

                            <div style={{fontSize: "3.0rem", marginBottom: "px"}}>
                                <span className={"credit-rating-" + data.creditRating}>{data.creditRating}</span>
                            </div>

                            <div style={{fontSize: "1.0rem", marginBottom: "5px"}}>
                                Provider score: {data.creditScore}
                            </div>
                            <div style={{fontSize: "1.5rem", marginBottom: "-12px"}}>
                                {data.creditRatingDesc}
                            </div>

                    </PricingCard>
                </Grid.Col>

                <Grid.Col sm={4}>
                    <PricingCard>

                            <PricingCard.Category>Payment trend</PricingCard.Category>

                            <div style={{fontSize: "3.0rem", marginBottom: "15px"}}>
                                { renderTidyTrend(data.paymentTrend) }
                            </div>

                            <div style={{fontSize: "1.5rem"}}>
                                &nbsp;
                            </div>

                    </PricingCard>
                </Grid.Col>
                <Grid.Col sm={4}>
                    <PricingCard>

                            <PricingCard.Category>Latest Turnover Figure</PricingCard.Category>

                            <div style={{fontSize: "3.0rem", marginBottom: "15px"}}>
                                {renderMonetaryValue(data.latestTurnover ) }
                            </div>

                            <div style={{fontSize: "1.5rem"}}>
                                {renderValueOrNull(data.latestTurnoverCurrency)}&nbsp;
                            </div>

                    </PricingCard>
                </Grid.Col>

                <Grid.Col sm={4}>
                    <PricingCard>

                            <PricingCard.Category>Credit limit</PricingCard.Category>

                            <div style={{fontSize: "3.0rem", marginBottom: "15px"}}>
                                {renderMonetaryValue(data.creditLimit ) }
                            </div>

                            <div style={{fontSize: "1.5rem"}}>
                                {renderValueOrNull(data.creditLimitCurrency)}
                            </div>

                    </PricingCard>
                </Grid.Col>


                <Grid.Col sm={4}>
                    <PricingCard>

                            <PricingCard.Category>Shareholder equity</PricingCard.Category>

                            <div style={{fontSize: "3.0rem", marginBottom: "15px"}}>
                                {renderMonetaryValue(data.equityValue ) }
                            </div>

                            <div style={{fontSize: "1.5rem"}}>
                                {renderValueOrNull(data.equityCurrency)}
                            </div>

                    </PricingCard>
                </Grid.Col>

                <Grid.Col sm={4}>
                    <PricingCard>

                            <PricingCard.Category>Group/ultimate owner</PricingCard.Category>

                            <div style={{fontSize: "1.5rem", marginTop: "45px", marginBottom: "15px", height: "3.75em"}}>
                                { renderNullAsNA(data.ultimateParentName) }
                            </div>

                            <div style={{fontSize: "1.5rem"}}>
                                { renderNullAsNA(data.ultimateParentCountry, "") || <>&nbsp;</> } &nbsp;
                            </div>

                    </PricingCard>
                </Grid.Col>

                <Grid.Col sm={8}>
                    <PricingCard>

                            <PricingCard.Category>Credit score history</PricingCard.Category>

                            <div style={{marginBottom: "-20px"}}>

                                {this.state.ratingHistory ?
                                    <C3Chart
                                        data={{
                                            x: "x",
                                            columns: [
                                                ["x"].concat(this.state.ratingHistory.filter(row=>row["rating"] > 0).map(row=>row["dt"].split(" ")[0])),
                                                ["Rating"].concat(this.state.ratingHistory.filter(row=>row["rating"] > 0).map(row=>row["rating"])),
                                            ],
                                            type: "area", // default type of chart
                                            colors: {
                                                data1: "#3982cd",
                                            }
                                        }}
                                        axis={{
                                            x: {
                                                type: "timeseries",
                                                tick: {
                                                    format: "%Y-%m"
                                                }
                                            },
                                            y: {
                                                min: 10,
                                                max: 90
                                            }
                                        }}
                                        legend={{hide:true}}
                                        point={{show:false}}
                                        size={{height: 200}}
                                        padding={{
                                            bottom: 0,
                                            top: 0,
                                        }}

                                    />
                                    :
                                    <LoadingIndicator/>
                                }
                            </div>

                    </PricingCard>
                </Grid.Col>



            </Grid.Row>

            <RenderRaw data={this.state.raw} />

        </>
    }

}

export default withSnackbar(withUserInfo(CompanyFinancials));
