import React from "react";

import {LoadingIndicator} from "../utils/LoadingIndicator";

import {
    Card,
    Grid, Table,
    Button, Icon
} from "tabler-react";

import api from "../../api";
import Utils from "../../utils";

import {withSnackbar} from "notistack";
import {StockLoadingOverlay} from "../utils/StockLoadingOverlay";

const Entry = (props) =>
  <Table.Row>
      <Table.Col>{props.name}</Table.Col>
      <Table.Col>{props.value}</Table.Col>
      <Table.Col>{props.actions}</Table.Col>
  </Table.Row>
;

const formatDate = (dt) => dt ? Utils.formatDateTime(dt) : "Never";

class DataAdmin extends React.Component {

    state = {

    }

    componentWillMount() {

        const { id } = this.props.match.params;

        api.get( "/suppliers/" + id + "/curation", {}).then((response) => {

            this.setState({company: response.data.company});

        }).catch(err => {
            this.setState({company: {error:true}});
        });


    }

    // Determine whether the company should be appearing in search results or not
    // (Actually in the index as opposed to the control fields which only show intent, but not whether the indexer has caught up)
    //
    companyIsIndexed = (company) => {

        if( company.Curated )
        {
            // Company is curated - that is, "set" to be indexed. But has it been done?
            return company.LastIndexed && (!company.RemovedFromIndex || company.LastIndexed > company.RemovedFromIndex );
        }
        else {
            // Company is not curated - that is, never curated or has been staged for removal.
            return !company.RemovedFromIndex || company.RemovedFromIndex > company.LastIndexed;
        }

    }

    companyIndexStatus = (company) => {

        if( company.Curated )
        {
            // Company is curated - that is, "set" to be indexed. But has it been done?
            if( company.LastIndexed && (!company.RemovedFromIndex || company.LastIndexed > company.RemovedFromIndex ))
                return "Indexed";
            else
                return "Staged for indexing";
        }
        else {
            // Company is not curated - that is, never curated or has been staged for removal.
            if( !company.RemovedFromIndex || company.RemovedFromIndex > company.LastIndexed )
                return "Not indexed";
            else
                return "Staged for removal";
        }

    }

    updateCurated = ( ) => {

        const { id } = this.props.match.params;
        let {company} = this.state;

        this.setState({busy: true});
        api.post( "/suppliers/" + id + "/curation", {action: "curate", value: !company.Curated}).then((response) => {

            company.Curated = company.Curated ? null : response.data.data.timestamp;
            this.setState({company});

        }).catch( err => {
            this.props.enqueueSnackbar( "Failed to update index; see logs");
        }).finally( ()=> {
            this.setState({busy: false});
        });

    }

    updateDemo = ( ) => {

        const { id } = this.props.match.params;
        let {company} = this.state;

        this.setState({busy: true});
        api.post( "/suppliers/" + id + "/curation", {action: "demo", value: !company.DemoCompany}).then((response) => {

            company.DemoCompany = !company.DemoCompany;
            this.setState({company});

        }).catch( err => {
            this.props.enqueueSnackbar( "Failed to update demo company; see logs");
        }).finally( ()=> {
            this.setState({busy: false});
        });

    }

    render() {

        if( !this.state.company)
             return <LoadingIndicator/>;

        const {company} = this.state;

        return <StockLoadingOverlay busy={this.state.busy}>

            <Grid.Row>

                <Grid.Col>

                    <Card>
                        <Card.Body>

                            <Table
                                responsive
                                hasOutline

                                cards
                                className="text-nowrap"
                            >
                                {company.error ?
                                    <>

                                        <p>
                                            <Icon prefix="fe" name="alert-triangle" /> &nbsp;
                                            The indexer could not be reached. Are you running a dev instance without VPN Markyboy?
                                        </p>
                                    </>
                                    :
                                    <Table.Body>

                                        <Entry name={"Index status"} value={company.indexStatus}/>
                                        <Entry name={"Include in index?"} value={company.Curated ? "Yes" : "No"}
                                               actions={
                                                   <Button color="primary" size={"sm"}
                                                           onClick={this.updateCurated}>{company.Curated ? "Remove from index" : "Add to index"}</Button>
                                               }
                                        />
                                        <Entry name={"Include in demo?"} value={company.DemoCompany ? "Yes" : "No"}
                                               actions={
                                                   <Button color="primary" size={"sm"}
                                                           onClick={this.updateDemo}>{company.DemoCompany ? "Remove from demo" : "Add to demo"}</Button>
                                               }
                                        />

                                        <Entry name={"Indexed since (curated)"} value={formatDate(company.Curated)}/>
                                        <Entry name={"Removed from index"}
                                               value={formatDate(company.RemovedFromIndex)}/>

                                        <Entry name={"Last indexed"} value={formatDate(company.LastIndexed)}/>
                                        <Entry name={"Last index size"} value={Utils.formatBytes(company.IndexSize)}/>
                                        <Entry name={"Last batch ID"} value={company.LastBatchId}/>

                                    </Table.Body>
                                }
                            </Table>

                        </Card.Body>
                    </Card>

                </Grid.Col>
            </Grid.Row>

        </StockLoadingOverlay>
    }

}

export default withSnackbar(DataAdmin);
