import React from "react";

import {Button, Card, Table} from "tabler-react";

import { withStyles } from "@material-ui/styles";

import {Link} from "react-router-dom";

import dayjs from "dayjs";

import utils from "../../utils";
import api from "../../api";

import {LoadingIndicator} from "../utils/LoadingIndicator";
import {withSnackbar} from "notistack";

const styles = utils.stockStyles;


class UserInvites extends React.Component {

    state = {
        invites: null
    }

    componentDidMount() {

        api.get("/auth/invites").then(response => {
            this.setState( {invites: response.data.invites});
        }).catch(err => {
            this.setState({errorMessage: err.message});
        });

    }

    resendInvite = (invite) => {

        api.post( "/auth/resend-invite", {id: invite.id}, {}).then((response) => {

            this.props.enqueueSnackbar("User invite resent");
            this.setState( {redirect: true});

        }).catch(err => {

            // Pull message from err.response.data.message if available, fall back to err.message
            //
            this.props.enqueueSnackbar("Failed to resend invite: " + api.getErrorMessage(err));

        });


    }

    render() {

        if( !this.state.invites)
            return <LoadingIndicator/>;

        return <Card>

            <Card.Header>
                <div style={{float:"right"}}>

                    <Link to={ "/admin/invite" }>
                        <Button
                            size="sm"
                            color="primary"
                        >
                            Send invite
                        </Button>
                    </Link>
                </div>
                <Card.Title >Outstanding invites</Card.Title>

            </Card.Header>

            <Table
                responsive
                className="card-table table-vcenter text-nowrap"

                headerItems={[
                    { content: "Date/time" },
                    { content: "Invitee" },
                    { content: "Email" },
                    { content: "Invited by" },
                    { content: "Used" },
                    { content: null },
                ]}

                bodyItems={ this.state.invites.map((item) => {

                    return( {key: item.id, item: [
                            {
                                content:  utils.formatLongDate(item.created) + ", " + dayjs(item.created).format("HH:mm:ss")
                            }, {
                                content: item.firstName ? (item.firstName + " " + item.lastName) : <i>{item.name}</i>
                            }, {
                                content: item.email
                            }, {
                                content: item.invitedBy
                            }, {
                                content: item.used ? utils.formatLongDate(item.created) + ", " + dayjs(item.created).format("HH:mm:ss") : <span className="text-secondary">Not yet</span>
                            }, {
                                content: item.used ? "" : <><a href={"/register/" + item.uuid}>Open</a> | <Link onClick={()=> this.resendInvite(item)}>Resend</Link></>
                            }
                        ]})

                })}

            />
        </Card>
            ;
    }

}

export default withSnackbar(withStyles(styles)(UserInvites));
