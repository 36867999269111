import React from "react";

import dayjs from "dayjs";

import decode from "jwt-decode"

import {Config} from "./config";
import Utils from "./utils";
import api from "./api";

const tokenName = "embedded-id-token";

export function withUserInfo(Wrapped) {

    return class extends React.Component {

        state = { user:null}

        componentDidMount() {

            // Use the information we have client-side until we have loaded the user profile
            // from the server.
            //
            let auth = new Auth();
            const user = auth.getProfileFromToken();
            console.log("Got user", user)
            this.setState({user, loaded:true});

            api.userReady((user) => {
                this.setState({user, loaded:true});
            });

        }

        render() {
            if( !this.state.loaded)
                return null;

            return <Wrapped user={api.getUser()} {...this.props} isAdmin={this.state.user && this.state.user.userType === Auth.ADMIN}  />;
        }

    };
}

export default class Auth {

    static ADMIN = "admin";
    static DEVELOPER = "dev";

    login = (username, password) => {

        return this.fetch( Config.apiEndpoint + "/auth/login", {

            method: "POST",
            body: JSON.stringify({username, password})

        }).then( res => {

            this.setToken( res.token );
            return(Promise.resolve(res));

        });
    };

    loggedIn = () => {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // Getting token from localstorage
        return !!token && !this.isTokenExpired(token); // handwaiving here
    };

    static isValidLicence = (user) => {

        console.log("Expires", user.licenceExpires);

        if( !user.licenceExpires)
            return false;

        const gracePeriod = 3 + 1;  // 3 extra days, plus one to cover remainder of 'today'. This should be server-config.

        const exp = dayjs(user.licenceExpires, Utils.Formatting.SqlTimestamp);
        const expiryWithGracePeriod = dayjs(exp).add(gracePeriod, "days");
        console.log("Expires with grace period", expiryWithGracePeriod.format("YYYY-MM-DD HH:mm:ss"));
        console.log("Yet to expire", expiryWithGracePeriod.isAfter(dayjs(), "day"));
        return( expiryWithGracePeriod.isAfter(dayjs(), "day"));

    };

    isTokenExpired = token => {

        try {

            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                // Checking if token is expired.
                return true;
            } else
                return false;

        } catch (err) {
            console.log("expired check failed! Line 42: AuthService.js");
            return false;
        }
    };

    setToken = idToken => {
        // Saves user token to localStorage
        localStorage.setItem(tokenName, idToken);
    };

    getToken = () => {
        // Retrieves the user token from localStorage
        let token = localStorage.getItem(tokenName);
        return(token);
    };

    logout = () => {
        // Clear user token and profile data from localStorage
        localStorage.removeItem(tokenName);
    };

    getProfileFromToken = () => {
        // Using jwt-decode npm package to decode the token
        let token = this.getToken();
        if( token ) {
            let answer = decode(this.getToken());
            return answer;}
        else console.log ("No user token");
        return(null);
    };

    fetch = (url, options) => {
        // performs api calls sending the required authentication headers
        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json"
        };

        // Setting Authorization header
        // Authorization: Bearer xxxxxxx.xxxxxxxx.xxxxxx
        if (this.loggedIn()) {
            headers["Authorization"] = "Bearer " + this.getToken();
        }

        return fetch(url, {
            headers,
            ...options
        })
            .then(this._checkStatus)
            .then(response => response.json());
    };

    _checkStatus = response => {

        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {

            // Success status lies between 200 to 300
            return response;

        } else {

            var error = new Error(response.statusText);
            error.response = response;
            throw error;

        }
    };


}

