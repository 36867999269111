import * as React from "react";
import {Redirect, Link} from "react-router-dom";
import { Grid, PricingCard, Card, Button} from "tabler-react";

import Auth from "../auth";

class LandingPage extends React.Component {

    state = {
        loggedIn: false
    }

    processLogin = (login, setSubmitting) => {

        //
        // Check stuff here...
        //

        setSubmitting( true );

        let auth = new Auth();
        this.setState( {errorMessage :null});

        auth.login(login.email, login.password).then((res) => {

            //
            // Login successful. Wait for localStorage to catch up before redirect
            //
            let timer = setInterval(() => {

                let profile = auth.getProfileFromToken();
                if( profile != null ) {

                    console.log( "Got profile. Redirecting");
                    clearInterval(timer);
                    this.setState({loggedIn: auth.loggedIn()});
                    setSubmitting(false);
                }
                else
                    console.log( "Waiting for localStorage");

            }, 100);


        }).catch( err => {

            //
            // failed somehow
            //
            setSubmitting(false);
            this.setState( {errorMessage :"Login failed"});

        });
    }

    componentWillMount() {

    }

    render()
    {
        if( this.state.loggedIn)
        {
            const {location}  =this.props;
            if( location && location.state && location.state.from)
                return( <Redirect to={location.state.from} />);
            else
                return( <Redirect to={"/"} />);
        }

        return <>

            <center>
                <h3>&nbsp;</h3>
                <h3>Welcome to the Embedded Intelligence Portal</h3>
                <h3>&nbsp;</h3>
            </center>
            <Grid.Row>
                <Grid.Col md={4} offsetMd={2}>
                    <PricingCard title="Register">
                        <PricingCard.Category>Register</PricingCard.Category>
                        <Card.Body>

                            <div className={"landing-card-height"}>
                                <p>Register to gain free access to a limited database of suppliers</p>

                                <p>
                                    Paid subscriptions allow access to the full database of UK Tech Companies, curated news feeds from Tech Media outlets and all new features as they are developed
                                </p>
                            </div>
                            <Link to={{pathname: "/register"}} >
                                <Button color={"primary"} className={"landing-page-button"}>
                                    Register
                                </Button>
                            </Link>
                        </Card.Body>
                    </PricingCard>
                </Grid.Col>
                <Grid.Col md={4}>
                    <PricingCard>
                        <PricingCard.Category>New to Embedded Intelligence?</PricingCard.Category>
                        <Card.Body>
                            <div className={"landing-card-height"}>
                                <p>Learn more about our platform and related services</p>
                            </div>
                            <a href={"https://www.embedded-intelligence.com/"} target={"_blank"} >
                                <Button  className={"landing-page-button"}>
                                    Learn more
                                </Button>
                            </a>
                        </Card.Body>
                    </PricingCard>
                </Grid.Col>

            </Grid.Row>
        </>;

    }
}

export default LandingPage;
