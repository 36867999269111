import * as React from "react";
import { Container, Grid, List } from "tabler-react";

const versionString = require( "../version");

const SiteFooter = ()=> (
    <>

        <div className="footer" style={{marginTop: "4em"}}>
            <Container>
                <Grid.Row>
                    <Grid.Col lg={12}>
                        <Grid.Row>

                            <>
                                <Grid.Col width={6} md={2}>
                                    <List unstyled={true} className="mb-0">
                                        <List.Item>
                                            <a href="https://embedded-intelligence.com/about-us" rel="noopener noreferrer" target="_blank">About</a>
                                        </List.Item>
                                    </List>
                                </Grid.Col>
                                <Grid.Col width={6} md={2}>
                                    <List unstyled={true} className="mb-0">
                                        <List.Item>
                                            <a href="https://embedded-intelligence.com/terms-and-conditions" rel="noopener noreferrer" target="_blank">Terms &amp; conditions</a>
                                        </List.Item>
                                    </List>
                                </Grid.Col>
                                <Grid.Col width={6} md={2}>
                                    <List unstyled={true} className="mb-0">
                                        <List.Item>
                                            <a href="https://embedded-intelligence.com/privacy-policy/" rel="noopener noreferrer" target="_blank">Privacy policy</a>
                                        </List.Item>
                                    </List>
                                </Grid.Col>
                                <Grid.Col width={6} md={2}>
                                    <List unstyled={true} className="mb-0">
                                        <List.Item>
                                            <a href="https://embedded-intelligence.com/contact" rel="noopener noreferrer" target="_blank">Contact us</a>
                                        </List.Item>
                                    </List>
                                </Grid.Col>
                            </>

                        </Grid.Row>
                    </Grid.Col>
                </Grid.Row>
            </Container>
        </div>

        <footer className="footer">
            <Container>
                <Grid.Row className="align-items-center">

                    <Grid.Col md={8}  className="mt-3 mt-lg-0">
                        <>
                            Copyright © 2024 <a href="https://www.embedded-it.co.uk/" target="_blank" rel="noopener noreferrer">Embedded IT Limited</a>. All rights reserved.
                            <p className={"small"}>v.{versionString}</p>
                        </>
                    </Grid.Col>


                </Grid.Row>
            </Container>
        </footer>
    </>
);

export default SiteFooter;

