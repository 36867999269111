import React from "react";

import {Page, PricingCard,  Grid} from "tabler-react";

import {withUserInfo} from "../../auth";

class BuyLicence extends React.Component {

    state = {}

    render() {

        const {user} = this.props;
        console.log("Render user", user)

        const directoryPriceCards =             <Grid.Row>

            <Grid.Col sm={4} offsetSm={2}>
                <PricingCard>

                    <PricingCard.Category>Pay monthly</PricingCard.Category>

                    <PricingCard.Price>&pound; 25.00 </PricingCard.Price>

                    <PricingCard.AttributeList>
                        <PricingCard.AttributeItem>
                            {"excl. VAT"}
                        </PricingCard.AttributeItem>
                        <PricingCard.AttributeItem>
                            {"Unlimited directory search"}
                        </PricingCard.AttributeItem>
                        <PricingCard.AttributeItem>
                            {"Financial profiles"}
                        </PricingCard.AttributeItem>
                        <PricingCard.AttributeItem>
                            {"Early adopter pricing"}
                        </PricingCard.AttributeItem>
                    </PricingCard.AttributeList>

                    <a href={"https://buy.stripe.com/dR66qb9b88KS2g89AA?prefilled_email=" + user.email}>
                        <PricingCard.Button> {"Choose plan"} </PricingCard.Button>
                    </a>

                </PricingCard>
            </Grid.Col>

            <Grid.Col sm={4} >

                <PricingCard>

                    <PricingCard.Category>Pay annually</PricingCard.Category>

                    <PricingCard.Price>&pound; 250.00 </PricingCard.Price>

                    <PricingCard.AttributeList>
                        <PricingCard.AttributeItem>
                            {"excl. VAT"}
                        </PricingCard.AttributeItem>
                        <PricingCard.AttributeItem>
                            {"Unlimited directory search"}
                        </PricingCard.AttributeItem>
                        <PricingCard.AttributeItem>
                            {"Financial profiles"}
                        </PricingCard.AttributeItem>
                        <PricingCard.AttributeItem>
                            {"2 months free"}
                        </PricingCard.AttributeItem>
                    </PricingCard.AttributeList>

                    <a href={"https://buy.stripe.com/eVag0LcnkbX42g84gh?prefilled_email=" + user.email}>
                        <PricingCard.Button> {"Choose plan"} </PricingCard.Button>
                    </a>

                </PricingCard>

            </Grid.Col>

        </Grid.Row>;

        return <Page.Content title="Get access to Embedded Intelligence">

            <Grid.Row>

                <Grid.Col sm={4} offsetSm={4}>
                    <PricingCard>

                        <PricingCard.Category>News Feed</PricingCard.Category>

                        <PricingCard.Price>&pound; 50.00 </PricingCard.Price>

                        <PricingCard.AttributeList>
                            <PricingCard.AttributeItem>
                                {"incl. VAT"}
                            </PricingCard.AttributeItem>
                            <PricingCard.AttributeItem>
                                {"Daily industry news digest"}
                            </PricingCard.AttributeItem>
                            <PricingCard.AttributeItem>
                                {"Over 150 curated news sources"}
                            </PricingCard.AttributeItem>
                            <PricingCard.AttributeItem>
                                {"Filtered to your chosen keywords"}
                            </PricingCard.AttributeItem>
                        </PricingCard.AttributeList>

                        <a href={"https://buy.stripe.com/aEU5og62A29F2643cc?prefilled_email=" + user.email}>
                            <PricingCard.Button> {"Buy now"} </PricingCard.Button>
                        </a>

                    </PricingCard>
                </Grid.Col>


            </Grid.Row>


        </Page.Content>
    }

}

export default withUserInfo(BuyLicence);
