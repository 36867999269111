import * as React from "react";
import { Formik } from "formik";

import {Form, FormCard, Grid, FormTextInput} from "tabler-react";
import api from "../../api";

import {LoadingIndicator} from "../utils/LoadingIndicator";
import {withSnackbar} from "notistack";

import Utils from "../../utils";

class AccountDetails extends React.Component {

    state = {

    }

    componentDidMount() {

        api.userReady((user) => {
            this.setState({profile: user});
        });

    }

    processForm = (values, {setSubmitting}) => {

        //
        // Check stuff here...
        //

        setSubmitting( true );

        this.setState( {errorMessage :null});

        api.post( "/auth/profile", values).then((response) => {

            this.props.enqueueSnackbar("Account details updated");

        }).catch((err) => {

            console.error( err );
            this.setState( {errorMessage: (err.response ? err.response.data.message : err.message) });

        }).finally(() => {

            setSubmitting(false);

        });

    }

    render() {

        if (!this.state.profile)
            return <LoadingIndicator/>;

        return (
            <Formik

                enableReinitialize={true}

                initialValues={this.state.profile}

                validate={values => {

                    let errors = {};

                    if (!values.email) {
                        errors.email = "Required";
                    } else if (
                        !Utils.validators.email(values.email)
                    ) {
                        errors.email = "Invalid email address";
                    }

                    if (!values.title) {
                        errors.title = "Required";
                    }
                    if (!values.firstName) {
                        errors.firstName = "Required";
                    }
                    if (!values.lastName) {
                        errors.lastName = "Required";
                    }

                    if (!values.companyName) {
                        errors.companyName = "Required";
                    }
                    if (!values.website) {
                        errors.website = "Required";
                    }

                    return errors;
                }}
                onSubmit={this.processForm}
                render={({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                         }) => (

                    <Grid.Row>

                        <Grid.Col>
                            <FormCard
                                buttonText="Update"
                                title="Account details"
                                onSubmit={handleSubmit}
                            >

                                <Grid.Row>
                                    <Grid.Col sm={2}>
                                        <FormTextInput
                                            name="title"
                                            label="Title"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.title}
                                            error={errors && errors.title}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={5}>
                                        <FormTextInput
                                            name="firstName"
                                            label="First name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.firstName}
                                            error={errors && errors.firstName}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={5}>
                                        <FormTextInput
                                            name="lastName"
                                            label="Last name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.lastName}
                                            error={errors && errors.lastName}
                                        />
                                    </Grid.Col>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Col sm={6}>
                                        <FormTextInput
                                            name="email"
                                            label="Email address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.email}
                                            error={errors && errors.email}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={6}>
                                        <FormTextInput
                                            name="phone"
                                            label="Telephone number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.phone}
                                            error={errors && errors.phone}
                                        />
                                    </Grid.Col>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Col sm={6}>
                                        <FormTextInput
                                            name="companyName"
                                            label="Company name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.companyName}
                                            error={errors && errors.companyName}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={6}>
                                        <FormTextInput
                                            name="website"
                                            label="Company website"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.website}
                                            error={errors && errors.website}
                                        />
                                    </Grid.Col>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Col sm={6}>

                                        <FormTextInput
                                            name="role"
                                            label="Job title"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.role}
                                            error={errors && errors.role}
                                        />
                                    </Grid.Col>
                                    <Grid.Col sm={6}>
                                    </Grid.Col>
                                </Grid.Row>

                                {this.state.errorMessage &&
                                    <p style={{color:"red"}}>{this.state.errorMessage}</p>
                                }

                            </FormCard>
                        </Grid.Col>
                    </Grid.Row>

                )}
            />
        );
    }
}

export default withSnackbar(AccountDetails);
