// @flow

import * as React from "react";
import { Container, Site, Notification, /*AccountDropdown*/ } from "tabler-react";

import {Config} from "../config";
import {Avatar, Dropdown} from "tabler-react";
//import {Props} from "tabler-react/dist/components/AccountDropdown/AccountDropdown.react";

/*
const itemsFromDefaultOptions = (options) =>
    options.map(opt => (typeof opt === "string" ? defaultOptions[opt] : opt));
*/

function AccountDropdown({
     avatarURL,
     name,
     description,
     options,
     optionsRootComponent,
 }) {
    //const itemsObjects = itemsFromDefaultOptions(options);
    const itemsObjects = options;

    return (
        <Dropdown
            isNavLink
            triggerClassName="pr-0 leading-none"
            triggerContent={
                <React.Fragment>
                    {avatarURL && <Avatar imageURL={avatarURL} />}
                    <span className="ml-2 shrinky">
            <span className="text-default">{name}</span>
            <small className="text-muted d-block mt-1">{description}</small>
          </span>
                </React.Fragment>
            }
            position="bottom-end"
            arrow={true}
            arrowPosition="right"
            toggle={false}
            itemsObject={itemsObjects}
            itemsRootComponent={optionsRootComponent}
        />
    );
}

/**
 * The very top header bar of your website, containing the logo and some optional
 * action components, such as a NotificationTray or an AccountDropdown on the right hand side
 */
const SiteHeader = ({
    children,
    href,
    align,
    imageURL,
    alt,
    notificationsTray: notificationsTrayFromProps,
    accountDropdown: accountDropdownFromProps,
    navItems,
    onMenuToggleClick,
}) => {
    const notificationsTray =
        notificationsTrayFromProps &&
        React.createElement(Notification.Tray, notificationsTrayFromProps);

    const accountDropdown =
        accountDropdownFromProps &&
        React.createElement(AccountDropdown, accountDropdownFromProps);

    let systemDescription = "";
    if( Config.sysName === "dev")
        systemDescription = " - DEV";

    return (
        <div className="header py-4">
            <Container className={align}>
                <div className="d-flex">
                    {children || (
                        <>
                            <Site.Logo href={href} alt={alt} src={imageURL} />
                            <div style={{fontSize: "0.9rem", display: "inline-block", marginTop:"10px", marginLeft: "-10px", lineHeight: "1em", color: "#bce"}}>BETA<span style={{color:"#d00", fontWeight: "bold"}}>{systemDescription}</span></div>
                            <div className="d-flex order-lg-2 ml-auto">

                                {navItems}
                                {notificationsTray}
                                {accountDropdown}

                            </div>

                        </>
                    )}
                </div>
            </Container>
        </div>
    );
};

export default SiteHeader;
