import React  from 'react';
import {Switch, Route, NavLink, withRouter, Link} from "react-router-dom";

import {LoadingIndicator} from "../utils/LoadingIndicator";

import {
    Page,
    Nav, Icon, Button
} from "tabler-react";

import api from "../../api";
import {withUserInfo} from "../../auth";

import CompanyOverview from "./CompanyOverview";
import CompanyFocus from "./CompanyFocus";
import CompaniesHouse from "./CompaniesHouse";
import WordCloud from "./WordCloud";
import Financials from "./Financials";
import DataAdmin from "./DataAdmin";
import Utils from "../../utils";


class CompanyWrapper extends React.Component {

    state = {

    }

    componentWillMount() {

        const { id } = this.props.match.params;

        api.get( "/suppliers/" + id + "/overview", {}).then((response) => {

            // Don't worry if it's not working...
            //
            api.post( "/suppliers/" + id + "/register-view", {});

            this.setState({company: response.data.company});

        });

    }

    updateCompanyWatch = () => {

        const {company} = this.state;

        if( company.isWatching) {
            api.delete("/feeds/watchlist", {params: {companyNumber: company.CompanyNumber}}).then(res => {
                company.isWatching = false;
                this.setState({company});
            });

        }
        else
        {
            api.post("/feeds/watchlist", {companyNumber: company.CompanyNumber}).then(res => {
                company.isWatching = true;
                this.setState({company});
            });

        }

    }

    render() {

        const { match } = this.props;
        const {company} = this.state;

        if( !company )
            return <LoadingIndicator/>

        return <>

            <Page.Header
                title={company.CompanyName}
                subTitle={<>Reg. no:&nbsp;&nbsp; <span style={{color: "#000"}}>{company.CompanyNumber}</span>
                    <span style={{marginLeft: "2rem"}}>Homepage:&nbsp;&nbsp; <a href={company.URL} style={{color: "#467fcf"}} target="_blank" rel="noopener noreferrer">{company.URL}</a></span>
                    <span style={{marginLeft: "2rem"}}>
                        {company.isWatching ?
                            <>
                                <span style={{color: "#000"}}>
                                <Icon prefix="fe" name="eye"/> Watching
                                </span>&nbsp;
                                <span style={{color: "#a00"}}>
                                <Link onClick={this.updateCompanyWatch}><Icon prefix="fe" name="x-square" /> </Link>
                                </span>
                            </>
                            :
                            <Link onClick={this.updateCompanyWatch}><Icon prefix="fe" name="eye"/> Add to
                                watchlist</Link>
                        }
                    </span>


                </>}
            />



            <Nav className={"hoisted-nav"}>
                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)} to={`${match.url}`}>Overview</Nav.Item>
                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)} to={`${match.url}/focus`}>Focus</Nav.Item>
                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)} to={`${match.url}/word-cloud`}>Keyword cloud</Nav.Item>
                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)} to={`${match.url}/companies-house`}>Companies House</Nav.Item>
                <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)} to={`${match.url}/financials`}>Financials</Nav.Item>

                {this.props.isAdmin &&
                    <Nav.Item useExact={true} LinkComponent={withRouter(NavLink)} to={`${match.url}/data-admin`}>Data
                        admin</Nav.Item>
                }

            </Nav>

            <Switch>
                <Route exact path={`${match.path}`} component={CompanyOverview} />
                <Route exact path={`${match.path}/focus`} component={CompanyFocus} />
                <Route exact path={`${match.path}/word-cloud`} component={WordCloud} />
                <Route exact path={`${match.path}/companies-house`} component={CompaniesHouse} />
                <Route exact path={`${match.path}/financials`} component={Financials} />
                {this.props.isAdmin &&
                    <Route exact path={`${match.path}/data-admin`} component={DataAdmin}/>
                }
            </Switch>

        </>
    }

}

export default withUserInfo(CompanyWrapper);
