import React from "react";
import {Link} from "react-router-dom";
import {Page, Card} from "tabler-react";

class PurchaseConfirmation extends React.Component {

    state = {}

    render() {

        return <Page.Content title="Get access to Embedded Intelligence">

            <Card>
                <Card.Body>
                    <h4>Purchase confirmation</h4>
                    <p>Thank you for your subscription!</p>
                    <p>Click <Link to={"/"}>here</Link> to start searching the directory.</p>
                </Card.Body>
            </Card>


        </Page.Content>
    }

}

export default PurchaseConfirmation;
