import React from "react";

import {LoadingIndicator} from "./LoadingIndicator";
const logoImage = require('../../assets/ei-logo-color-sm.png');

export const LoadingPage = props => (

    <>
        <div style={{textAlign:"center", paddingTop: "200px"}}>
            <img src={logoImage} alt="Embedded Intelligence" width="300"  />
            <LoadingIndicator/>
        </div>
    </>


);
