/**
 * Observable class.
 *
 * Ported from my Observable from my original Lui library.
 * Mj~
 *
 */

export default class Observable
{
    _delegates = {};
    _otoEvents = {};
    _depth = null;
    _maxDepth = 20;

    constructor()
    {
    }

    /**
     *    Add event handler
     */
    on (eventName, handler, scope) {

        console.log("Registering handler", arguments)
        // Make sure multiple copies of a delegate cannot be accidentally registered.
        // This should really be on the caller to correctly 'un' when done, but I'm feeling generous.
        this.un(eventName, handler, scope);

        if (this._delegates === null)
            this._delegates = {};
        if (this._delegates[eventName] === undefined)
            this._delegates[eventName] = [];

        var delegate = { handler: handler, scope: scope || this };
        this._delegates[eventName].push(delegate);

        // Check if it is a one-time only event, and whether it has already passed.
        //
        if (this._otoEvents && this._otoEvents[eventName])
            delegate.handler(...(this._otoEvents.args || []));
            //delegate.handler.apply(delegate.scope, this._otoEvents.args || []);
    }

    /**
     * Remove event handler
     *
     * @param eventName
     * @param handler
     */
    un (eventName, handler)
    {
        if (this._delegates === null) return;

        var ds = this._delegates[eventName];
        if (ds === undefined)
            return;

        for (var i = 0; i < ds.length; i++)
        {
            var d = ds[i];
            if (d.handler === handler)
            {
                ds.splice(i, 1);
                break;
            }
        }
    }

    hasDelegates(eventName)
    {
        if (this._delegates === null) return false;

        var ds = this._delegates[eventName];
        if (ds === undefined)
            return false;

        return true;
    }

    /***
     *
     * Behaves like *.fire() except that if there are no delegates, the default handler
     * provided will be called.
     *
     * DEV NOTE: This could not easily be implicit because within fire() it would be impossible to tell whether a given parameter
     * is a default handler, or an actual parameter to the event handler. So we make the call explicit.
     *
     * @param eventName
     * @param defaultHandler
     * @param scope
     */
    fireOrDefault  (eventName, defaultHandler, scope)
    {
        var args = [];
        for (var a = 3; a < arguments.length; a++)
            args.push(arguments[a]);

        if (this.hasDelegates( eventName ))
        {
            args.splice(0, 0, eventName);
            this.fire(args);
        }
        else
        {
            if (defaultHandler)
                defaultHandler(args);
        }
    }

    /**
     *
     * @param eventName
     *
     * Fires a "one time" event - where fire() will fire whenever an event occurs, fireOnce assumes the event happens only once,
     * and as such will call the delegate immediately if the named event has already happened.
     *
     */
    fireOnce (eventName)
    {
        console.log("Firing one-time event", eventName)
        var args = [];
        for (let a = 1; a < arguments.length; a++)
            args.push(arguments[a]);

        this._otoEvents[eventName] = { name: eventName, args: args };   // Anything we want to attach, data-wise?

        args.splice(0, 0, eventName);

        this.fire(...args);
        //this.fire.apply(this, args);
    }

    /**
     * @param eventName
     *
     * Fires the named event, calling all registered delegates (subscribers)
     */
    fire (eventName)
    {
        console.log("Firing", arguments)
        if (this._delegates === null) return;

        var ds = this._delegates[eventName];

        if (ds === undefined)
            return;

        // Prevent stack overflow due to events being raised in a loop.
        // For example handling of errors is delegated but if something goes wrong in the event handler code,
        // it will raise another error condition which in turn attempts to use the same handling code again,
        // ad infinitum.
        //
        this._depth = this._depth || {};
        this._depth[eventName] = this._depth[eventName] || 0;
        if (++this._depth[eventName] > this._maxDepth)
        {
            if (typeof (console) != "undefined" && console.error) console.error("Event stack overflow");
            return;
        }

        var args = [];
        for (var a = 1; a < arguments.length; a++)
            args.push(arguments[a]);

        for (var i = 0; i < ds.length; i++)
        {
            try
            {
                if (ds[i].handler)
                    ds[i].handler(...args);
                    //ds[i].handler.apply(ds[i].scope, args);
            }
            catch (err)
            {
                throw err;
            }
        }
        this._depth[eventName]--;
    }

}
