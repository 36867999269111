import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Form} from "tabler-react";

const formatFilterName = (val) => {

    return( val.charAt(0).toUpperCase() + val.slice(1));

}

export const FilterGroup = (props) => {

    const [showAll, setShowAll] = useState( false);

    const limit = props.limit || 10;
    const filter = props.filter;
    const showFilters = showAll ? props.filterValues : props.filterValues.slice(0,limit);

    console.log(showAll, limit, showFilters);

    return <>

        <h4>{formatFilterName(filter.name)}</h4>

        {showFilters.map( val => {

            return <>

                <Form.Checkbox
                    checked={props.isSelectedFilter(filter.name, val.name)}
                    value={val.name}
                    name={"filter-" + filter.name}
                    label={val.name  + " (" + val.count + ")"}
                    onChange={(e) => props.filterChange(filter.name, val.name, e.target.checked) }
                />

            </>
        })}

        {!showAll && props.filterValues.length > limit &&
            <p class={"center"}><Link onClick={() => setShowAll(true)}>({(props.filterValues.length - limit)} more...)</Link></p>
        }

    </>
    ;

};

export default FilterGroup;
