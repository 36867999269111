import React, {Component} from 'react';

import {Map, TileLayer, Marker, Polygon} from "react-leaflet";

const  DefaultZoom = 14;

class MapInput extends Component {


    markerRef = null;

    state = {}

    componentDidMount() {

        if( !this.props.location)
            throw new Error ("No location given for MapInput");

        this.setState( {location: this.props.location, zoom: this.props.zoom || DefaultZoom, markerLocation: this.props.markerLocation || this.props.location} );

    }

    handleMapMove = (viewport) => {

        const [lat,lng] = viewport.center;
        this.setState({location: {lat,lng}, zoom: viewport.zoom});

        if( this.props.onMapMove )
        {
            this.props.onMapMove({lat,lng, zoom: viewport.zoom});
        }
    }

    handleMarkerMove = (e) => {

        console.log("Marker: ", e.target);

        let ll = e.target._latlng;

        this.setState({markerLocation:ll});

        if (this.props.onDragEnd) {
            this.props.onDragEnd(ll);
        }

    }

    render() {

        let props = this.props;

        /*
        return <span>

            <GoogleMap

                ref={(mapRef) => this.ref = mapRef}
                options={createMapOptions}
                controlSize={15}
                defaultZoom={props.zoom || 14}
                defaultCenter={props.location}
                onDragEnd={this.handleMapMove}
                center={props.forceLocation}
            >

                {props.showMarker &&
                <Marker position={props.markerLocation || props.forceLocation || props.location} {...props.marker}
                        draggable={props.draggable} onDragEnd={props.onDragEnd}/>}

            </GoogleMap>
        </span>;

         */

        return <>
            <Map center={this.state.location} zoom={this.state.zoom} onViewportChanged={this.handleMapMove}>
                <TileLayer
                    attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                {this.props.showRect &&
                    <Polygon color={"grey"} positions={this.props.showRect} weight="1" opacity={0.5} />
                }

                {   props.showMarker &&
                <Marker key={"pin-x"} position={this.state.markerLocation} draggable={props.draggable}
                        onDragEnd={this.handleMarkerMove}
                        ref={(markerRef) => this.markerRef = markerRef}
                         />
                }
            </Map>

            </>;
    }

}

export default MapInput;
