import React from "react";

import api from "../api";

import {Button, Card, Grid, Table, Page} from "tabler-react";
import {Link, Redirect} from "react-router-dom";
import {FormTextInput} from "tabler-react";
import {Formik} from "formik";
import {SmallLoadingIndicator} from "./utils/LoadingIndicator";
import {withUserInfo} from "../auth";
import LoadingOverlay from "react-loading-overlay";

import FilterGroup from "./search/FilterGroup";

const PAGE_SIZE = 10;


const renderWebAddress = (url) => {

    if( !url ) return;
    const charpos = url.indexOf( "/", 9 );
    if( charpos > -1 )
        return( url.substring(0, charpos)); // Truncate to the first slash after the domain
    return(url.substring(0,20) + "...");    // Some other trunctation

}

/*
const renderBusinessModels = (models) => {

    // These lists can get long... let's show no more than 3 to avoid clutter.
    //
    const numberToShow = 3;
    const asArray = models.split( "," );
    const suffix = ( asArray.length > numberToShow ? ", and " + (asArray.length - numberToShow ) + " more": "");
    return( asArray.slice(0,3).join( ", ") + suffix);

}
 */

const SearchIndexEntry = (props) => {

    const {item} = props;

    return <>

        <Table.Row key={item.index}>

            <Table.Col className={"search-results-name-col-fixed"}>
                <Link to={"/co/" + item.CompanyNumber}>{item.CompanyName}</Link>

                <p className={"table-secondary-text"}>{(item.mainModel)}</p>

            </Table.Col>
            <Table.Col>
                <a href={item.URL} target={"_blank"}>{renderWebAddress(item.URL)}</a>
            </Table.Col>
            <Table.Col>
                {item.RegAddressPostTown}
            </Table.Col>
            <Table.Col>
                {item.turnover}
            </Table.Col>
            {props.isAdmin &&
                <Table.Col>
                    {item.score}
                </Table.Col>
            }
        </Table.Row>

    </>;

}

const SearchByNameEntry = (props) => {

    const {item} = props;

    return <>

        <Table.Row key={item.index}>
            <Table.Col>
                {item.CompanyNumber}
            </Table.Col>

            <Table.Col className={"search-results-name-col-fixed"}>
                <Link to={"/co/" + item.CompanyNumber}>{item.CompanyName}</Link>

                <p className={"table-secondary-text"}>{item.RegAddressPostTown}</p>

            </Table.Col>
            <Table.Col>
                <a href={item.URL} target={"_blank"}>{item.URL}</a>
            </Table.Col>
        </Table.Row>

    </>;

}

class Dashboard extends React.Component {

    state = {
        filters: [],
        page: 0
    }

    ogSingle = () => {

        api.get("/ogsingle/").then( res => {

            let upd = this.state;

            if( res.data.error )
                upd.bad++
            else
                upd.good++;
            upd.calls++;
            this.setState( upd);

            // Call again.
            ///
            this.ogSingle();

        }).finally(() => {

        });

    }

    componentDidMount() {

        //this.ogSingle();

        let currentUrlParams = new URLSearchParams(window.location.search);
        let q = currentUrlParams.get('q');
        let f = currentUrlParams.get('f');
        let p = parseInt(currentUrlParams.get('p') || 0);

        api.userReady( user => {

            this.setState( {user});
            this.prepareAndRunQuery(q, f, p);

        });

    }

    prepareAndRunQuery = (q, f, p) => {

        this.setState( {q, f, p}, () => {
            this.runQuery();
        } );

    }

    processForm = (values) => {

        const {q} = values;

        // Update the URL and reload the page
        //
        this.setState( {q, p:0}, () => {
            this.runQuery();
        } );

    }

    goPage = (page) => {

        this.setState( {p:page}, () => {
            this.runQuery();
        } );

    }

    runQuery = () => {

        const {q, f, p} = this.state;
        if (!q)
            return;

        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('q', q);
        currentUrlParams.set('f', f);
        currentUrlParams.set('p', p);
        this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());

        this.setState({runningQuery: true});

        api.get("/facet-search/" + q + "?" + (f || "f=") + "&start=" + ((p || 0) * PAGE_SIZE) ).then( res => {

            this.setState( {indexResults: res.data, query: q, page:p});

        }).finally(() => {
            this.setState( {runningQuery: false });
        });

    }

    filterChange = (filterName, filterValue, selected) => {

        // Update filters then reload results
        //
        console.log("Filter", filterName, filterValue);

        let filters = this.state.filters;

        if( selected )
            filters.push( {name: filterName, value: filterValue} );
        else
            filters = filters.filter( i =>  ( i.name !== filterName || i.value !== filterValue) );

        //let fquery = filters.map( f => ( f.name ));
        let f = "";
        //console.log("Filters", filters, filters.length);

        let fa = filters.map( i => ( i.name + ":\"" + i.value + "\"" ));
        f = "f=" + fa.join( ";") + "";

        this.setState({ filters, f}, () => {
            this.runQuery();
        } );

    }

    isSelectedFilter = (filterName, filterValue) => {

        const {filters} = this.state;
        const found = filters.find( f => ( f.name === filterName && f.value === filterValue ) );
        //console.log(filterName, filterValue, (found != null));
        return( found != null );
    }

    render() {

        if( this.state.redirectToLogin)
            return( <Redirect to={"/login"} />);

        const {user, nameResults, indexResults, page} = this.state;

        //console.log("Filters", this.state.filters);

        return <LoadingOverlay
                active={this.state.runningQuery}
                fadeSpeed={300}
                spinner
                styles={{
                    overlay: (base) => ({
                        ...base,
                        background: 'rgba(245, 247, 251, 0.5)'
                    }),
                    spinner: (base) => ({
                        ...base,
                        '& svg circle': {
                            stroke: 'rgba(100, 100, 110, 0.5)'
                        }
                    })

                }
            }>
            <Page.Content title="What are you interested in?">

            <Grid.Row>

                <Grid.Col  sm={12}>

                    <Formik

                        enableReinitialize={true}

                        initialValues={
                            this.state
                        }

                        validate={values => {
                            // same as above, but feel free to move this into a class method now.
                            let errors = {};

                            if (!values.q) {
                                errors.q = "Required";
                            }

                            return errors;
                        }}

                        onSubmit={(
                            values,
                            {setSubmitting, setErrors /* setValues and other goodies */}
                        ) => {

                            this.processForm( values, setSubmitting );

                        }}

                        render={({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                         }) => (

                            <>

                                <Grid.Row>
                                    <Grid.Col md={6}>
                                        <FormTextInput
                                            label={null}
                                            name="q"
                                            placeholder="What do you need?"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyPress={(e)=>{
                                                if( e.key === "Enter")
                                                    handleSubmit();
                                            }}
                                            value={values && values.q}
                                            error={errors && errors.q}
                                        />
                                    </Grid.Col>

                                    <Grid.Col md={1}>
                                        <Button type="submit" color="primary" onClick={handleSubmit} >Search</Button>
                                    </Grid.Col>

                                    <Grid.Col md={1}>
                                        { this.state.runningQuery && false && <SmallLoadingIndicator />}
                                    </Grid.Col>

                                </Grid.Row>
                            </>
                        )}
                    />

                </Grid.Col>

            </Grid.Row>

            {indexResults ?
                <Grid.Row>
                    <Grid.Col md={3}>
                        <Card className={"filter-group"}>
                            <Card.Body>


                                { indexResults.filters.map( (filter) => {

                                    const availableFilters = filter.values.filter( val => val.count > 0 );
                                    if( availableFilters.length )
                                        return <FilterGroup filter={filter} filterValues={availableFilters} isSelectedFilter={this.isSelectedFilter} filterChange={this.filterChange} />;

                                    return null;

                                })}

                            </Card.Body>
                        </Card>
                    </Grid.Col>

                    <Grid.Col md={9}>

                        <Card>
                            <Card.Body>

                                {nameResults && nameResults.suppliers && nameResults.suppliers.length > 0 &&
                                <>
                                            <p>Your query has matched <b>{nameResults.suppliers.length.toLocaleString()}</b> suppliers by name. {this.state.showNameSearch ? "" : <Link onClick={()=>{this.setState({showNameSearch:true})}}>Click to view</Link>}</p>
                                            {this.state.showNameSearch &&
                                            <Table
                                                striped={true}
                                                responsive
                                                collapsible
                                                className="card-table table-vcenter"
                                                headerItems={[
                                                    {content: ""},
                                                    {content: "Name", className: "search-results-name-col-fixed"},
                                                    {content: "Website"},
                                                    {content: null},
                                                ]}
                                            >

                                                {nameResults.suppliers.slice(0, 25).map((item) => {

                                                    return <SearchByNameEntry item={item}/>

                                                })}

                                            </Table>
                                            }

                                </>
                                }

                                <p><b>{(page * PAGE_SIZE) + 1}</b> to <b>{(page * PAGE_SIZE) + PAGE_SIZE}</b> of <b>{indexResults.totalSuppliers.toLocaleString()}</b> total catalogue matches for <b>{this.state.query}</b>.</p>

                                {indexResults.dataset === "demo" && <>
                                    <div className={"demo-warning"}>You are currently viewing our demo dataset. <Link to={"/buy"}>Click here to see your upgrade options</Link></div>
                                </>}


                                <Table
                                    striped={true}
                                    responsive
                                    className="card-table table-vcenter"
                                    headerItems={[
                                        { content: "Name", className: "search-results-name-col-fixed"},
                                        { content: "Website" },
                                        { content: "Location" },
                                        { content: "Turnover" },
                                        { content: null },
                                    ]}
                                    >

                                    {indexResults.suppliers.slice(0, 25).map((item) => {

                                        return <SearchIndexEntry item={item} isAdmin={this.props.isAdmin}/>

                                    })}

                                </Table>

                                <div className={"pagination"}>
                                    <span>
                                    {page > 0 && <>
                                        <Link onClick={()=> {this.goPage(page-1)}}>&lt;&lt; Previous </Link>
                                        </>
                                    }
                                    </span>
                                    <span>
                                    {page < ((indexResults.totalSuppliers / PAGE_SIZE) -1 ) && <>
                                        <Link onClick={()=> {this.goPage(page+1)}}>Next &gt;&gt;</Link>
                                        </>
                                    }
                                    </span>
                                </div>

                            </Card.Body>
                        </Card>

                    </Grid.Col>
                </Grid.Row>

            :
            <>
                {user && user.recentSearches && user.recentSearches.length > 0 &&
                    <>
                        <Grid.Row>
                            <Grid.Col md={6}>

                                <Card title="Recent searches">

                                    <Card.Body className="some-padding">

                                        <Table
                                            responsive
                                            highlightRowOnHover

                                            cards
                                            className="text-nowrap  compact no-borders no-left-padding"
                                        >

                                            {user.recentSearches.slice(0,5).map( search => {
                                                return <Table.Row key={search.query}>
                                                    <Table.Col>
                                                        <Link onClick={() => {
                                                            this.prepareAndRunQuery(search.query)
                                                        }}>{search.query}</Link>
                                                    </Table.Col>
                                                </Table.Row>;
                                            })}

                                        </Table>

                                    </Card.Body>
                                </Card>

                                <Card title="Recently viewed">

                                    <Card.Body className="some-padding">

                                        <Table
                                            responsive
                                            highlightRowOnHover

                                            cards
                                            className="text-nowrap  compact no-borders no-left-padding"
                                        >

                                            {user.recentViews.slice(0,5).map( view => {
                                                return <Table.Row key={view.CompanyNumber}>
                                                    <Table.Col>

                                                        <Link to={"/co/" + view.CompanyNumber}>{view.CompanyName}</Link>

                                                    </Table.Col>
                                                    <Table.Col>
                                                        <span
                                                            className={"text-secondary"}>{view.CompanyNumber}</span>
                                                    </Table.Col>
                                                </Table.Row>;
                                            })}

                                        </Table>

                                    </Card.Body>
                                </Card>

                            </Grid.Col>
                        </Grid.Row>
                    </>
                }
            </>
            }

        </Page.Content></LoadingOverlay>
    }

}

export default withUserInfo(Dashboard);
