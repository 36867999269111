import React from "react";

import api from "../../api";
import Utils from "../../utils";

import {Button, Card, Grid, Table} from "tabler-react";
import {Link} from "react-router-dom";
import {FormTextInput} from "tabler-react";
import {Formik} from "formik";
import {SmallLoadingIndicator} from "../utils/LoadingIndicator";
import {withUserInfo} from "../../auth";

/*
const renderWebAddress = (url) => {

    if( !url ) return;
    const charpos = url.indexOf( "/", 9 );
    if( charpos > -1 )
        return( url.substring(0, charpos)); // Truncate to the first slash after the domain
    return(url.substring(0,20) + "...");    // Some other trunctation

}

const renderBusinessModels = (models) => {

    // These lists can get long... let's show no more than 3 to avoid clutter.
    //
    const numberToShow = 3;
    const asArray = models.split( "," );
    const suffix = ( asArray.length > numberToShow ? ", and " + (asArray.length - numberToShow ) + " more": "");
    return( asArray.slice(0,3).join( ", ") + suffix);

}

const formatDate = (dt) => {
    return( dt ? Utils.formatDateTime(dt) :  <span class='text-secondary'>Never</span>);
}
 */

const twoLineDateTime = (dt) => {

    return <>
        {dt ? Utils.formatLongDate(dt) : <span className='text-secondary'>Never</span> }
        {dt &&
            <p className={"table-secondary-text"}>{Utils.format24Hr(dt)}</p>
        }
    </>

}

const SearchByNameEntry = (props) => {

    const {item} = props;

    return <>

        <Table.Row key={item.index}>
            <Table.Col md={5} >

                <Link to={"/co/" + item.CompanyNumber}>{item.CompanyName}</Link>
                <p className={"table-secondary-text"}>{item.CompanyNumber} - <a href={item.URL} target={"_blank"}>{item.URL}</a></p>

            </Table.Col>
            <Table.Col>
                {item.RegAddressPostTown}
            </Table.Col>
            <Table.Col>
                {twoLineDateTime(item.LastUpdated)}
            </Table.Col>
            <Table.Col>
                {twoLineDateTime(item.LastIndexed)}
            </Table.Col>
            <Table.Col>
                {twoLineDateTime(item.Curated)}
            </Table.Col>
            <Table.Col>
                {item.DemoCompany ? "Yes" : "No"}
            </Table.Col>
        </Table.Row>

    </>;

}

class CompanyIndex extends React.Component {

    state = {
        filters: []
    }

    prepareAndRunQuery = (q) => {

        this.setState( {q}, () => {
            this.runQuery();
        } );

    }

    processForm = (values) => {

        const {q} = values;

        // Update the URL and reload the page
        //
        this.setState( {q}, () => {
            this.runQuery();
        } );

    }

    runQuery = () => {

        const {q} = this.state;
        if (!q)
            return;

        this.setState({runningQuery: true});

        api.get("/admin/search-by-name", {params:{ q}}).then(res => {

            console.log("Search by name", res.data);
            this.setState({nameResults: res.data});

        }).finally(() => {
            this.setState({runningQuery: false});
        });


    }

    showDemoCompanies= () => {

        this.setState({runningQuery: true});

        api.get("/admin/get-demo-companies").then(res => {

            this.setState({nameResults: res.data});

        }).finally(() => {
            this.setState({runningQuery: false});
        });

    }

    render() {

        const {nameResults } = this.state;

        //console.log("Filters", this.state.filters);

        return <>
            <Grid.Row>

                <Grid.Col  sm={12}>

                    <Formik

                        enableReinitialize={true}

                        initialValues={
                            this.state
                        }

                        validate={values => {
                            // same as above, but feel free to move this into a class method now.
                            let errors = {};

                            if (!values.q) {
                                errors.q = "Required";
                            }

                            return errors;
                        }}

                        onSubmit={(
                            values,
                            {setSubmitting, setErrors /* setValues and other goodies */}
                        ) => {

                            this.processForm( values, setSubmitting );

                        }}

                        render={({
                                     values,
                                     errors,
                                     touched,
                                     handleChange,
                                     handleBlur,
                                     handleSubmit,
                                     isSubmitting,
                                 }) => (

                            <>

                                <Grid.Row>
                                    <Grid.Col md={6}>
                                        <FormTextInput
                                            label={null}
                                            name="q"
                                            placeholder="Search by company name or number"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            onKeyPress={(e)=>{
                                                if( e.key === "Enter")
                                                    handleSubmit();
                                            }}
                                            value={values && values.q}
                                            error={errors && errors.q}
                                        />
                                    </Grid.Col>

                                    <Grid.Col md={1}>
                                        <Button type="submit" color="primary" onClick={handleSubmit} >Search</Button>
                                    </Grid.Col>

                                    <Grid.Col md={1}>
                                        <Button type="button" color="primary" onClick={this.showDemoCompanies} >Demo companies</Button>
                                    </Grid.Col>

                                    <Grid.Col md={1}>
                                        { this.state.runningQuery && false && <SmallLoadingIndicator />}
                                    </Grid.Col>

                                </Grid.Row>
                            </>
                        )}
                    />

                </Grid.Col>

            </Grid.Row>

            {nameResults && <Grid.Row>

                <Grid.Col md={12}>

                    <Card>
                        <Card.Body>

                            {nameResults && nameResults.suppliers && nameResults.suppliers.length > 0 &&
                                <>
                                    <Table
                                        striped={true}
                                        responsive
                                        collapsible
                                        className="card-table table-vcenter"
                                        headerItems={[
                                            {content: "Name", className: "search-results-name-col-fixed"},
                                            {content: "Town"},
                                            {content: "Last updated"},
                                            {content: "Last indexed"},
                                            {content: "Curated"},
                                            {content: "Demo?"},
                                        ]}
                                    >

                                        {nameResults.suppliers.map((item) => {

                                            return <SearchByNameEntry item={item}/>

                                        })}

                                    </Table>
                                </>
                            }

                        </Card.Body>
                    </Card>

                </Grid.Col>
            </Grid.Row>
            }
        </>

    }

}

export default withUserInfo(CompanyIndex);
