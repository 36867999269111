import React, { Component } from 'react';
import SiteWrapper from "./lib/SiteWrapper";

import Login from './lib/Login';
import Register from './lib/Register';
import Auth from './auth';
import PasswordResetRequest from './lib/PasswordResetRequest';
import PasswordReset from './lib/PasswordReset';

import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";

import './App.css';
import "tabler-react/dist/Tabler.css";


const Logout = () => {
    auth.logout();
    return <Redirect to="/" />;
};

const auth = new Auth();

//
// Main App class
//
class App extends Component {

    direct = (nextState, replace) => {
        //console.log('Redirecting');

        //if (auth.loggedIn()) replace({ pathname: '/portal' });
        //else replace({ pathname: '/login' });
    };


    render() {

    // <StripeProvider apiKey={Config.stripePublicKey} >

    return (

        <Router>
            <Switch>
                <Route path="/login" exact component={Login} />
                <Route path="/logout" exact component={Logout} />
                <Route path="/register/:uuid" component={Register} />
                <Route path="/register" component={Register} />
                <Route path="/reset-password/:uuid" component={PasswordReset} />
                <Route path="/reset-password" component={PasswordResetRequest} />
              <Route path="/" component={SiteWrapper}  onEnter={this.direct} />
            </Switch>

        </Router>

    );
  }
}

export default App;
