import React from "react";
import axios from "axios";
import utils from "../../utils";
import api from "../../api";

import {renderValueOrNull, renderMonetaryValue, renderMonthByName} from "../utils/Renderers";


import {LoadingIndicator} from "../utils/LoadingIndicator";

import {
    Card,
    Grid, PricingCard,
    Table
} from "tabler-react";

import {withSnackbar} from "notistack";
import MapInput from "../utils/MapInput";
import StarRatings from 'react-star-ratings';


const makeAddress = (company) => {

    let a = [company.RegAddressCareOf +  " "+company.RegAddressAddressLine1,
     company.RegAddressAddressLine2,
     company.RegAddressPostTown,
     company.RegAddressCounty,
     company.RegAddressPostCode
    ].filter(r => r && r.length );

    return a.map( a => ( <>{a}<br/></> ) );

}

const makeCompanyStatus = (status) => {

    return( status.charAt(0).toUpperCase() + status.slice(1));

}


class CompanyOverview extends React.Component {

    state = {

    }

    componentWillMount() {

        this.reload();

        //this.setState({property: this.props.property});
    }

    reload = () => {

        const { id } = this.props.match.params;

        api.get( "/suppliers/" + id + "/overview", {}).then((response) => {

            const {company} = response.data;

            if( company )
            {
                let url = "https://nominatim.openstreetmap.org/search?q=" + company.RegAddressPostCode + "&format=json&polygon=1&addressdetails=1";

                axios.get( url ).then( res => {

                    let first = res.data[0];

                    company.lat = parseFloat(first.lat);
                    company.lng  = parseFloat(first.lon);

                    this.setState({company: company});

                }).catch( err => {
                    console.log("Failed to fetch lat/lng from postcode", err.message);
                })



            }

        });


    }

    render() {

        const {company} = this.state;

        if( !company )
            return <LoadingIndicator/>;

        const financials = company.financials || {};

        return <>

            <Grid.Row>

                <Grid.Col md={7}>

                    <Card title={"General information"}>
                        <Card.Body>

                            <div style={{height:"360px", width: "100%", marginBottom: "15px"}}>

                                <MapInput
                                    showMarker={true}
                                    draggable={true}
                                    marker={utils.mapPin}
                                    zoom={12}
                                    location={{lat: this.state.company.lat, lng: this.state.company.lng}}
                                    onDragEnd={(ll)=> {this.setState({markerLocation: ll})}}
                                />

                            </div>

                            <Table
                                responsive
                                highlightRowOnHover
                                hasOutline
                                
                                cards
                                className="text-nowrap"
                            >
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Col>
                                            Registered address
                                        </Table.Col>
                                        <Table.Col>
                                            {makeAddress(company)}
                                        </Table.Col>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Col>
                                            Company status
                                        </Table.Col>
                                        <Table.Col>
                                            {makeCompanyStatus(company.CompanyStatus)}
                                        </Table.Col>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Col>
                                            Year end
                                        </Table.Col>
                                        <Table.Col>
                                            {renderMonthByName(company.YearEndMonth)}
                                        </Table.Col>
                                    </Table.Row>

                                    {company.GoogleRating &&
                                        <Table.Row>
                                            <Table.Col>
                                                Google rating
                                            </Table.Col>
                                            <Table.Col>
                                                <StarRatings
                                                    rating={parseFloat(company.GoogleRating)}
                                                    starRatedColor="green"
                                                    changeRating={this.changeRating}
                                                    numberOfStars={5}
                                                    starDimension={"18px"}
                                                    starSpacing={"0px"}
                                                /> ({company.GoogleRating})

                                            </Table.Col>
                                        </Table.Row>
                                    }
                                </Table.Body>
                            </Table>
                        </Card.Body>
                    </Card>
                </Grid.Col>

                <Grid.Col md={5}>

                        <PricingCard>

                            <PricingCard.Category>Credit Rating</PricingCard.Category>

                            <div style={{fontSize: "3.0rem", marginBottom: "px"}}>
                                <span className={"credit-rating-" + financials.creditRating}>{financials.creditRating}</span>
                            </div>

                            <div style={{fontSize: "1.0rem", marginBottom: "5px"}}>
                                Provider score: {financials.creditScore || "n/a"}
                            </div>
                            <div style={{fontSize: "1.5rem", marginBottom: "-12px"}}>
                                {financials.creditRatingDesc}
                            </div>

                        </PricingCard>

                        <PricingCard>

                            <PricingCard.Category>Latest Turnover Figure</PricingCard.Category>

                            <div style={{fontSize: "3.0rem", marginBottom: "15px"}}>
                                {renderMonetaryValue(financials.latestTurnover ) }
                            </div>

                            <div style={{fontSize: "1.5rem"}}>
                                {renderValueOrNull(financials.latestTurnoverCurrency)}&nbsp;
                            </div>

                        </PricingCard>


                </Grid.Col>

            </Grid.Row>

        </>
    }

}

export default withSnackbar(CompanyOverview);
