import * as React from "react";
import { Formik } from "formik";
import {Redirect, Link} from "react-router-dom";
import FormCard from "./utils/FormCard";
import { FormTextInput, Grid} from "tabler-react";
import {StandaloneFormPage} from "./utils/StandaloneFormPage";

import api from "../api";

import Utils from "../utils";
let logoImage = Utils.siteLogo;

class PasswordResetRequest extends React.Component {

    state = {
        loggedIn: false
    }

    processLogin = (login, setSubmitting) => {

        //
        // Check stuff here...
        //

        setSubmitting( true );

        api.post( "/auth/password-reset", {email: login.email}).then(response => {

            this.setState( {resetComplete: true});

        }).catch( err => {
            this.setState( {errorMessage :  err.response.data.message || "Request failed"});
        }).finally(() => {
            setSubmitting(false);
        });

    }

    componentWillMount() {

    }

    render()
    {
        if( this.state.loggedIn)
            return( <Redirect to={"/"} />);

        return (
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validate={values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors = {};
                    if (!values.email) {
                        errors.email = "Required";
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = "Invalid email address";
                    }
                    return errors;
                }}
                onSubmit={(
                    values,
                    {setSubmitting, setErrors /* setValues and other goodies */}
                ) => {

                    this.processLogin( values, setSubmitting );

                }}
                render={({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                         }) => (

                    <StandaloneFormPage formClass="anythingElse" imageURL={logoImage} imageAlt="Darabase. Control your reality">

                        {this.state.resetComplete ?
                            <>
                                <p style={{textAlign:"center"}}>
                                    If the given email address corresponds to an account with us, you will receive an email with further instructions.
                                </p>
                                
                            </>
                            :
                            <>
                                <p style={{textAlign:"center"}}>
                                    To reset your password, enter your email address below and we will send you an email with further instructions.
                                </p>

                                <Grid.Col md={4} offsetMd={4}>
                                    <FormCard
                                        buttonText="Reset password"
                                        title="Password reset"
                                        onSubmit={handleSubmit}
                                        isSubmitting={isSubmitting}
                                    >

                                        {this.state.errorMessage &&
                                            <p style={{color:"red"}}>{this.state.errorMessage}</p>
                                        }

                                        <FormTextInput
                                            name="email"
                                            label="Email address"
                                            placeholder="Email address"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.email}
                                            error={errors && errors.email}
                                            disabled={isSubmitting}
                                        />

                                    </FormCard>
                                </Grid.Col>
                            </>
                        }

                        <p style={{textAlign:"center"}}>
                            <Link to="/login">Return to login page</Link>
                        </p>

                    </StandaloneFormPage>

                )}
            />
        );
    }
}

export default PasswordResetRequest;
