import * as React from "react";
import { Formik } from "formik";
import {Redirect, Link} from "react-router-dom";
import FormCard from "./utils/FormCard";
import { FormTextInput, Grid} from "tabler-react";
import {StandaloneFormPage} from "./utils/StandaloneFormPage";

import Auth from "../auth";

import Utils from "../utils";
let logoImage = Utils.siteLogo;

class LoginPage extends React.Component {

    state = {
        loggedIn: false
    }

    processLogin = (login, setSubmitting) => {

        //
        // Check stuff here...
        //

        setSubmitting( true );

        let auth = new Auth();
        this.setState( {errorMessage :null});

        auth.login(login.email, login.password).then((res) => {

            //
            // Login successful. Wait for localStorage to catch up before redirect
            //
            let timer = setInterval(() => {

                let profile = auth.getProfileFromToken();
                if( profile != null ) {

                    console.log( "Got profile. Redirecting");
                    clearInterval(timer);
                    this.setState({loggedIn: auth.loggedIn()});
                    setSubmitting(false);
                }
                else
                    console.log( "Waiting for localStorage");

            }, 100);


        }).catch( err => {

            //
            // failed somehow
            //
            setSubmitting(false);
            this.setState( {errorMessage :"Login failed"});

        });
    }

    componentWillMount() {

    }

    render()
    {
        if( this.state.loggedIn)
        {
            const {location}  =this.props;
            if( location && location.state && location.state.from)
                return( <Redirect to={location.state.from} />);
            else
                return( <Redirect to={"/"} />);
        }


        return (
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validate={values => {
                    // same as above, but feel free to move this into a class method now.
                    let errors = {};
                    if (!values.email) {
                        errors.email = "Required";
                    } else if (
                        !Utils.validators.email(values.email)
                    ) {
                        errors.email = "Invalid email address";
                    }
                    return errors;
                }}
                onSubmit={(
                    values,
                    {setSubmitting, setErrors /* setValues and other goodies */}
                ) => {

                    this.processLogin( values, setSubmitting );

                }}
                render={({
                             values,
                             errors,
                             touched,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                             isSubmitting,
                         }) => (

                    <StandaloneFormPage formClass="anythingElse" imageURL={logoImage} imageAlt="Embedded Intelligence">

                        <p style={{textAlign:"center"}}>
                            Welcome to the Embedded Intelligence Directory.
                        </p>

                        <Grid.Row>
                        <Grid.Col lg={4} offsetLg={4} md={6} sm={12} >
                        <FormCard
                            buttonText="Login"
                            title="Login"
                            onSubmit={handleSubmit}
                            isSubmitting={isSubmitting}
                        >

                            {this.state.errorMessage &&
                            <p style={{color:"red"}}>{this.state.errorMessage}</p>
                            }

                            <FormTextInput
                                name="email"
                                label="Email address"
                                placeholder="Email address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values && values.email}
                                error={errors && errors.email}
                                disabled={isSubmitting}
                            />
                            <FormTextInput
                                name="password"
                                type="password"
                                label="Password"
                                placeholder={
                                    "Password"
                                }
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values && values.password}
                                error={errors && errors.password}
                                disabled={isSubmitting}
                            />


                            <p style={{textAlign:"center"}}>
                                <Link to="/reset-password">Forgotten password?</Link>
                            </p>

                        </FormCard>
                        </Grid.Col>

                        </Grid.Row>

                    </StandaloneFormPage>

                )}
            />
        );
    }
}

export default LoginPage;
