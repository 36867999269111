import * as React from "react";
import Auth, {withUserInfo} from "../auth";
import {Link, Redirect} from "react-router-dom";


// Simple wrapper that requires a valid user and license, or redirects to the purchase call-to-action
//
class SecureWrapper extends React.Component {

    render() {

        const {user} = this.props;

        /*
        Allow unlicensed users access to the "secure" areas for demo purposes
        
        if( user && !Auth.isValidLicence(user)) {
            return <Redirect to={{pathname: "/buy", state: {from: this.props.location}}}/>
        }
        */


        return <>
            {this.props.children}
        </>
    }
}

export default withUserInfo(SecureWrapper);
