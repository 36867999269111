import React from "react";
import {Redirect} from "react-router-dom";

import {
    Card,
    Grid,
    Button,
    FormTextInput,
    PricingCard, 

} from "tabler-react";

import api from "../../api";
import utils from "../../utils";

import {withSnackbar} from "notistack";
import {Formik} from "formik";

class UserInvite extends React.Component {

    state = {
        invite: {}
    }

    componentDidMount() {

        api.get( "/system/orgs").then(response => {
            this.setState( {orgs: response.data.orgs});
        });

    }

    processForm = (values, {setSubmitting /* setErrors, setValues and other goodies */ }) => {

        setSubmitting(true);

        api.post( "/auth/invite", values, {}).then((response) => {

            this.props.enqueueSnackbar("User invite sent");
            this.setState( {redirect: true});

        }).catch(err => {

            // Pull message from err.response.data.message if available, fall back to err.message
            //
            this.props.enqueueSnackbar("Failed to send invite: " + api.getErrorMessage(err));

        }).finally(() => {

            setSubmitting(false);

        });

    }

    render() {

        if( this.state.redirect )
        {
            return <Redirect to={"/admin/user-invites"} />
        }

        return <>

            <Grid.Row>
                <Grid.Col sm={12} md={8} offsetMd={2}>
                    <Card className="some-padding">
                        <PricingCard.Category>Invite User</PricingCard.Category>
                        <Card.Body>

                            <div className="text-center d-block" >

                                <Formik

                                    enableReinitialize={true}

                                    initialValues={
                                        this.state.invite
                                    }

                                    validate={values => {
                                        // same as above, but feel free to move this into a class method now.
                                        let errors = {};

                                        if (!values.firstName) {
                                            errors.firstName = "Required";
                                        }
                                        if( !values.email)
                                            errors.email = "Required";

                                        if( !utils.validators.email(values.email))
                                            errors.email = "Must be a valid email address";

                                        return errors;
                                    }}

                                    onSubmit={this.processForm}

                                    render={({
                                         values,
                                         errors,
                                         touched,
                                         handleChange,
                                         handleBlur,
                                         handleSubmit,
                                         isSubmitting,
                                     }) => (

                                        <>

                                            <FormTextInput
                                                name="firstName"
                                                placeholder="First name"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.firstName}
                                                error={errors && errors.firstName}
                                            />
                                            <FormTextInput
                                                name="lastName"
                                                placeholder="Last name"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.lastName}
                                                error={errors && errors.lastName}
                                            />

                                            <FormTextInput
                                                name="email"
                                                placeholder="Email address"
                                                autoComplete="off"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values && values.email}
                                                error={errors && errors.email}
                                            />

                                            <br/>
                                            <br/>

                                            <Button type="submit" onClick={handleSubmit} color="primary" disabled={isSubmitting}>Send</Button>

                                        </>
                                    )}
                                />

                            </div>

                        </Card.Body>
                    </Card>
                </Grid.Col>

            </Grid.Row>
        </>


    }

}

export default withSnackbar(UserInvite);
