import Utils from "../../utils";
import dayjs from "dayjs";

const renderMonetaryValue = (amount) => {

    if(!amount) return "n/a";
    const parsed = parseInt( amount );
    if( isNaN(parsed)) return "n/a";

    return(Utils.formatCurrency(parsed / 100.0) );

}

const renderNullAsNA = (text, nullValue = "n/a") => {

    if( !text || text.toLowerCase() === "null")
        return( nullValue );
    return(text);

}

const renderValueOrNull = (text) => {

    return( renderNullAsNA(text, ""));

}


const renderMonthByName = (month) => {

    // Seriously though, why the fuck are months 0-11 ???
    // Needlessly confusing.
    //
    return( dayjs().set("month",month-1).format( "MMMM") );

}

const renderCoHouseTitle = (title) => {

    return(
        title.split("-").map( s => (s.charAt(0).toUpperCase() + s.slice(1)) ).join( " ")
    );

}


const renderCoHouseShareholderType = (kind ) => {

    if( kind === "individual-person-with-significant-control" || kind === "legal-person-person-with-significant-control" || kind === "super-secure-person-with-significant-control" || kind === "individual-beneficial-owner")
        return "Individual";
    if( kind === "corporate-entity-person-with-significant-control")
        return "Corporate";
    return "Unknown";

};

const renderCompanyType = (type) => {

    const types = {
        "ltd": "Private limited company",
        "converted-or-closed": "Converted/closed",
        "plc": "Public limited company",
        "private-unlimited": "Private unlimited company",
        "private-limited-guarant-nsc-limited-exemption": "Private Limited Company by guarantee without share capital use of 'Limited' exemption",
        "llp": "Limited liability partnership",
        "oversea-company": "Overseas company",
        "limited-partnership": "Limited partnership",
        "private-limited-guarant-nsc": "Private company limited by guarantee without share capital",
        "scottish-charitable-incorporated-organisation": "Scottish charitable incorporated organisation",
        "charitable-incorporated-organisation": "Charitable incorporated organisation",
        "registered-society-non-jurisdictional": "Registered society",
        "royal-charter": "Royal Charter company",
        "unregistered-company": "Unregistered company",
        "registered-overseas-entity": "Overseas entity",
        "uk-establishment": "UK establishment"
    };

    const check = types[type];
    return check || "Unknown";

}

export {
    renderValueOrNull,
    renderNullAsNA,
    renderMonetaryValue,
    renderMonthByName,
    renderCoHouseTitle,
    renderCoHouseShareholderType,
    renderCompanyType
};
