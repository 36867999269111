import * as React from "react";
import {withRouter, Route, Switch, Link, Redirect} from "react-router-dom";

import api from "../api";

import {
    RouterContextProvider, Page, Button,
} from "tabler-react";

import SiteHeader from "./SiteHeader";
import SiteFooter from "./SiteFooter";
import {LoadingPage} from "./utils/LoadingPage";

import dayjs from "dayjs";

import {MobileView, BrowserView} from "react-device-detect";
import BuyLicence from "./account/BuyLicence";
import ConfirmPurchase from "./account/ConfirmPurchase";

import Dashboard from "./Dashboard";
import CompanyWrapper from "./company/CompanyWrapper";
import AdminWrapper from "./admin/AdminWrapper";
import AccountWrapper from "./account/AccountWrapper";
import Auth from "../auth";
import SecureWrapper from "./SecureWrapper";
import LandingPage from "./Landing";

import {SnackbarProvider } from "notistack";


let logoImage = require('../assets/ei-logo-color-header-sm.png');

/*
const navBarItems = [
    {
        value: "Home",
        to: "/",
        icon: "home",
        LinkComponent: withRouter(NavLink),
        useExact: true,
    },
    {
        value: "Transactions",
        to: "/activity/transactions",
        icon: "file-text",
        LinkComponent: withRouter(NavLink)
    }

];

const adminNavItems = [
    {
        value: "Admin",
        to: "/admin",
        icon: "settings",
        LinkComponent: withRouter(NavLink)
    }
];

const developerNavItems = [
    {
        value: "Developers",
        to: "/dev",
        icon: "settings",
        LinkComponent: withRouter(NavLink)
    }
];
*/


class SiteWrapper extends React.Component {

    state = {
        notificationsObjects: null
    };

    nav = {};

    userDescription = "";

    componentDidMount() {

        api.userReady((user) => {

            let userDescription = "User";
            this.setState({user, userDescription: userDescription});

        });

        //
        // TODO: Display a friendly message here?
        //
    }

    render() {

        let auth = new Auth();
        //if( !auth.loggedIn())
        //    return <Redirect to={{ pathname: "/login", state: { from: this.props.location }}} />

        let publicRoutesFullApp = [
            <Route path={`/buy`} exact component={BuyLicence}/>,
            <Route path={`/purchase-confirmation`} exact component={ConfirmPurchase}/>,
        ];

        let publicRoutesReduced = [
            <Route path={`/buy`} exact component={BuyLicence}/>,
        ];

        let secureRoutesFullApp = [
            <Route path={`/`} exact component={auth.loggedIn() ? Dashboard : LandingPage}/>,
            <Route path={`/co/:id`} component={CompanyWrapper} />,
            <Route path={`/account`} component={AccountWrapper} />,
            <Route path={`/admin`} component={AdminWrapper} />
        ];

        // Temporarily redirect root to the user account to edit newsfeed and prevent access to search facilities.
        //
        let secureRoutesReduced = [
            <Route path={`/`} exact render={()=>  <Redirect to={"/account"} />}/>,
            <Route path={`/co/:id`} component={CompanyWrapper} />,
            <Route path={`/account`} component={auth.loggedIn() ? AccountWrapper : LandingPage}/>,
            <Route path={`/search`} component={auth.loggedIn() ? Dashboard : LandingPage}/>,
            <Route path={`/admin`} component={AdminWrapper} />
        ];


        let accountDropdownProps = {};
        //let nav = navBarItems;

        const buyButton = <Link to="/buy">
            <Button size={"sm"} color="primary">Buy</Button>
        </Link>;

        const loginButton = <Link to="/login">
            <Button size={"sm"} color="primary">Login</Button>
        </Link>

        const navItems = [this.state.user ? null : loginButton];

        if( this.state.user) {

            const {user} = this.state;

            let opts = [
                {icon: "user", value: "Account", to: "/account"},
                {icon: "log-out", value: "Log out", to: "/logout"}
            ];

            if( user.userType === "admin")
            {
                opts = [
                    {icon: "settings", value: "Admin tools", to: "/admin"},
                    {icon: "search", value: "Search", to: "/search"},
                    {isDivider: true}
                ].concat(opts);
            }

            const expires = dayjs(user.licenceExpires);

            accountDropdownProps = {
                name: user.firstName + (user.userType === "admin" ? " (Admin)" : ""),
                description: Auth.isValidLicence(user) ? "Licence until: " + expires.format( "DD MMM, YYYY") : "No licence",
                options: opts
            };

            /*
            if (user.userType === "admin")
                nav = nav.concat(adminNavItems);
            if ((user.userType === "admin" || user.userType === "dev"))
                nav = nav.concat(developerNavItems);

             */
        }

        return (
            <SnackbarProvider maxSnack={3}>
                <Page routerContextComponentType={withRouter(RouterContextProvider)}>

                    <Page.Main>


                            {this.state.user || true ?
                                <>

                                    <SiteHeader
                                        {...{
                                            href: "/",
                                            alt: "Embedded Intelligence BETA",
                                            imageURL: logoImage,
                                            tagline: "BETA",

                                            navItems: [<div>
                                                {navItems}
                                            </div>],
                                            accountDropdown: accountDropdownProps,
                                        }} />

                                    <Page.Content>

                                        <Switch>
                                            {publicRoutesReduced}
                                        </Switch>

                                        <SecureWrapper>
                                            <Switch>
                                                {secureRoutesReduced}
                                            </Switch>
                                        </SecureWrapper>

                                    </Page.Content>
                                </>
                                : this.state.loadError ?

                                    <>
                                        <div style={{textAlign: "center"}}>
                                            <img style={{marginTop: "300px", marginBottom: "50px", width: "400px"}}
                                                 src={logoImage}
                                                 alt={"Embedded Intelligence"}/>
                                            <p>There was an error loading your profile. Please try clearing your browser
                                                cache
                                                or <a href="mailto:support@embedded-it.co.uk">contact support</a>.</p>
                                        </div>

                                    </>
                                    :
                                    <LoadingPage/>

                            }


                    </Page.Main>

                    <SiteFooter />

                </Page>
            </SnackbarProvider>
        );
    }
}

export default SiteWrapper;
