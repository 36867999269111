import React from "react";
import {Form, Card, Button} from "tabler-react";

function FormCard({
    children,
    action,
    method,
    onSubmit,
    title,
    buttonText,
    isSubmitting
})  {
    return <Form className="card" onSubmit={onSubmit} action={action} method={method}>
            <Card.Body className="p-6" disabled={isSubmitting}>
                <Card.Title RootComponent="div">{title}</Card.Title>
                {children}
                <Form.Footer>
                    <Button type="submit" color="primary" block={true} disabled={isSubmitting}>
                        {buttonText}
                    </Button>
                </Form.Footer>
            </Card.Body>
        </Form>
    ;
}

export default FormCard;
