import axios from "axios";
import {Config} from "./config";
import Auth from "./auth";
import Observable from "./observable";

//
// Helper class retrieves API endpoint from configuration and injects API key header
//
class API  extends Observable {

    _user = null;
    loading = false;

    _prepareOpts = (opts) => {

        opts = opts || {};
        let auth = new Auth();
        if( auth.loggedIn())
        {
            let profile = auth.getProfileFromToken();
            let token = auth.getToken();
            opts.headers = Object.assign(opts.headers || {}, {user: profile.id, "x-api-token": token});
        }

        let authHeaders = { "apikey": Config.apiKey };
        opts.headers = Object.assign(opts.headers || {}, authHeaders);

        return( opts );
    }

    get = (url, opts) => {
        opts = this._prepareOpts(opts);
        return(axios.get(Config.apiEndpoint + url, opts));
    };

    post = (url, data, opts) => {
        opts = this._prepareOpts(opts);
        return(axios.post(Config.apiEndpoint + url, data, opts));
    };

    delete = (url, opts) => {
        opts = this._prepareOpts(opts);
        return(axios.delete(Config.apiEndpoint + url, opts));
    };

    getUser = () => {

        return( new Promise( (resolve, reject) => {

            this.userReady( (user) => {
                resolve(user);
            });
            /*
            if( API._user )
                resolve( API._user);

            console.log("getUser()")
            this.get("/auth/profile").then( async response => {

                if( response.data && response.data.user)
                {
                    API._user = response.data.user;
                    resolve( API._user);
                    }
                else
                    reject( "Failed to load user profile:", response);

            }).catch( err => {
                reject(err.message);
            });
            
             */

        }));

    };

    userReady = (handler) => {

        this.on( "userReady", handler);

        if( !this._user && !this.loading ) {

            console.log("Loading user!!!")

            this.loading = true;
            this.get("/auth/profile").then(async response => {

                if (response.data && response.data.user) {

                    this._user = response.data.user;
                    this.fireOnce("userReady", this._user);
                }
            }).finally(() => {
                this.loading = false;
            });
        }
    }

    //
    // Get a helpful message from an axios catch parameter.
    //
    // Start with the possibility of something returned from the server, then normal catch logic,
    // then a friendly fallback message
    //
    getErrorMessage = (err) => {

        if( !err)
            return( "(No more information; code 1)");

        // The message from the server
        //
        if( err && err.response && err.response.data && err.response.data.message)
            return( err.response.data.message);

        // Standard exception trail
        //
        if (err.message)
            return( err.message );

        // Otherwise
        //
        return( "(No more information; code 2)");
    }

}

let api = new API();

export default api;
