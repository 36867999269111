import React from "react";

import api from "../../api";

import {Card, PricingCard, Table, Grid, Page} from "tabler-react";

import {LoadingIndicator} from "../utils/LoadingIndicator";

class Dashboard extends React.Component {

    state = {}

    componentDidMount() {

        api.get("/admin/stats").then(response => {

            this.setState( {stats: response.data.stats});

        }).catch(err => {
            this.setState({errorMessage: err.message});
        });

    }

    render() {

        if( !this.state.stats)
            return <LoadingIndicator/>;

        const {queryCounts, popularQueries} = this.state.stats;

        const list = [
            {name: "Last hour", count: queryCounts.lastHour},
            {name: "Today", count: queryCounts.today},
            {name: "Yesterday", count: queryCounts.yesterday},
            {name: "This week", count: queryCounts.thisWeek},
            {name: "Last week", count: queryCounts.lastWeek},
            {name: "This month", count: queryCounts.thisMonth},
            {name: "Last month", count: queryCounts.lastMonth},
            {name: "All time", count: queryCounts.allTime}
        ];

        return <Page.Content>

            <Grid.Row>

                <Grid.Col sm={4} offsetSm={1}>
                    <Card>
                        <Card.Body className="">

                            <PricingCard.Category>Recent activity</PricingCard.Category>

                            <Table
                                cards={true}
                                striped={true}
                                responsive={true}
                                small={true}
                                className="table-vcenter table-sm"
                            >
                                <Table.Body>
                                    {list.map( v =>
                                        <Table.Row>
                                            <Table.Col>{v.name}</Table.Col>
                                            <Table.Col alignContent={"right"}>{v.count}</Table.Col>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>

                        </Card.Body>
                    </Card>
                </Grid.Col>

                <Grid.Col sm={6}>
                    <Card>
                        <Card.Body className="">

                            <PricingCard.Category>Popular searches</PricingCard.Category>

                            <Table
                                cards={true}
                                striped={true}
                                responsive={true}
                                small={true}
                                className="table-vcenter table-sm"
                            >
                                <Table.Body>
                                    {popularQueries.map( q =>
                                        <Table.Row>
                                            <Table.Col>{q.query}</Table.Col>
                                            <Table.Col alignContent={"right"}>{q.count}</Table.Col>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>

                        </Card.Body>
                    </Card>
                </Grid.Col>

            </Grid.Row>


        </Page.Content>
    }

}

export default Dashboard;
